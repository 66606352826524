<template>
    <div class="select-container">
        <div class="select-group">
            <label for="department-select"><i class="fas fa-map-marker-alt icon-bg"></i> Département</label>
            <select id="department-select" v-model="selectedDepartment" @change="updateCities">
                <option v-for="department in departments" :key="department.id" :value="department.id">
                {{ department.name }}
                </option>
            </select>
        </div>

        <div class="select-group">
            <label for="city-select"><i class="fas fa-city  icon-bg"></i> Ville</label>
            <select id="city-select" v-model="selectedCity" @change="updateRegions">
                <option v-for="city in cities" :key="city">
                {{ city }}
                </option>
            </select>
        </div>
    </div>
    
    <!-- Liste des régions disponibles -->
    <div>
      <!-- Si loading est vrai, affiche l'indicateur de chargement -->
      <div v-if="loadingRegions">
        <i class="fas fa-spinner fa-spin"></i> <!-- Font Awesome spinner -->
        <p>Chargement des régions...</p>
      </div>

      <!-- Si les régions sont disponibles et que le chargement est terminé -->
      <div v-else-if="regions.length > 0" class="group-regions" @wheel="handleScroll" ref="scrollContainer">
        <div 
          v-for="region in regions" 
          :key="region.id"
          :class="{ active: selectedRegion && region.id === selectedRegion.id }" 
          @click="selectRegion(region)"
          class="region-item"
        >
          {{ region.name }}
        </div>
      </div>


      <!-- Si aucune région n'est disponible -->
      <div v-else>
        <p class="no-region">Aucune région disponible</p>
      </div>
    </div>


</template>

<script>
import axios from 'axios';
import { SERVER_IP } from '../../config';

import { departments, citiesByDepartment } from '../../utils/departments';
//trie des departements et des villes par ordre 
const selectedDepartments = departments.filter(department =>  [9, 8].includes(department.id));
const sortedDepartments = selectedDepartments.sort((a, b) => a.name.localeCompare(b.name));

Object.keys(citiesByDepartment).forEach(deprtmentId => {
  citiesByDepartment[deprtmentId].sort((a, b) => a.localeCompare(b));
});


export default {
    name: 'RegionSelector',
    data() {
        return {
            departments: sortedDepartments,
            citiesByDepartment: citiesByDepartment,
            
            selectedDepartment: 8,
            selectedCity: '',
            regions: [],
            regionsCity: [],
            cities: [],
            selectedRegion: null, 
            loadingRegions: false, // Indicateur de chargement
        
        }
    },
    methods: {
        updateCities() {
            this.cities = this.citiesByDepartment[this.selectedDepartment] || [];
            this.selectedCity = this.cities[0] || '';
            this.updateRegions();
        },
        
        selectRegion(region) {
          this.selectedRegion = region; // Stocke l'objet entier de la région
          this.$emit('region-selected', region); // Émettre l'événement avec la région sélectionnée
        },

        handleScroll(event) {
            const scrollContainer = this.$refs.scrollContainer;
            scrollContainer.scrollLeft += event.deltaY; // Utilise le défilement vertical de la souris pour faire défiler horizontalement
        },
        getDepartmentNameById(departmentId) {
            const department = this.departments.find(dept => dept.id === departmentId);
            return department ? department.name : 'Département inconnu';
        },

        // 1. Récupérer les régions via une API
        async fetchRegions() {
            try {
                const url = `${SERVER_IP}/daily-exchanges/fetchRegions`;
                const response = await axios.get(url); // Utilisez .get() au lieu de .post()
                return response.data.cities; // Assurez-vous que les données soient sous la forme attendue
            } catch (error) {
                console.error('Erreur lors de la récupération des régions:', error);
                throw error; // Rejeter l'erreur pour que l'appelant puisse gérer l'erreur
            }
        },

        // 2. Filtrer les villes sans région définie
        // 2. Filtrer les villes sans région définie
        filterCitiesWithRegions(citiesByDepartment, regionsCity) {
            
            // Créer un ensemble (Set) contenant les noms des villes avec des régions définies
            const validCities = new Set(regionsCity.map(city => city.name));

            // Épurer citiesByDepartment
            const filteredCitiesByDepartment = Object.keys(citiesByDepartment).reduce((acc, departmentId) => {
                // Filtrer les villes qui existent dans validCities
                const filteredCities = citiesByDepartment[departmentId].filter(city => validCities.has(city));
                
                // Si des villes restent après le filtrage, les ajouter au nouvel objet
                if (filteredCities.length > 0) {
                    acc[departmentId] = filteredCities;
                }
                
                return acc;
            }, {});

            // Retourner l'objet épuré
            return filteredCitiesByDepartment;
        },



        async updateRegions() {
          if (this.selectedCity) {
            this.loadingRegions = true;
            const url = `${SERVER_IP}/daily-exchanges/getRegions`;
            try {
              const response = await axios.post(url, { 
                  department: this.getDepartmentNameById(this.selectedDepartment),
                  city: this.selectedCity
              });
              this.regions = response.data.regions || [];
              this.selectRegion(this.regions[0]);
              
            } catch (error) {
              console.error("Erreur lors de la récupération des régions :", error);
            } finally {
              this.loadingRegions = false;
            }
          } else {
            this.regions = [];
          }
          
        },
        

    },

    mounted() {
        this.updateCities();
        this.fetchRegions()
        .then(regionsCity => {
            // Filtrez les villes avec les régions récupérées
            const filteredCities = this.filterCitiesWithRegions(this.citiesByDepartment, regionsCity);
            
            // Assurez-vous que l'objet citiesByDepartment est réactif
            this.citiesByDepartment = filteredCities; // Pour Vue 3
            // Pour Vue 2, vous pourriez faire : this.$set(this, 'citiesByDepartment', filteredCities);
            
            // Vérification après filtrage
            console.log('Cities by Department après filtrage:', this.citiesByDepartment);
            this.updateCities();
        })
        .catch(error => {
            console.error('Erreur lors de la récupération des régions ou du filtrage des villes:', error);
        });
    },
        
};

</script>

<style scoped>

label {
  margin-bottom: 0.5em;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

.select-container {
  display: flex;
  gap: 1em; /* Espace entre les éléments */
  margin-bottom: 1em;
}

.select-group {
  display: flex;
  flex-direction: column;
  width: 200px; /* Largeur fixe pour les éléments select */
}

select {
  padding: 0.5em;
  font-size: 1em;
  background-color: black;
  border: 2px solid #FFF;
  color: white;
  border-radius: 4px;
  outline: none;
}

select:focus {
  border-color: #016A40; /* Bordure plus claire lors du focus */
}

label {
  margin-bottom: 0.5em;
  color: white;
}
.group-regions {
  display: flex;
  overflow-x: auto; /* Permet le défilement horizontal */
  gap: 1em;
  padding: 5px;
  scrollbar-width: none; /* Masque la barre de défilement pour Firefox */
  margin-top: 0px;
}

.group-regions::-webkit-scrollbar {
  display: none; /* Masque la barre de défilement pour Chrome, Safari et Edge */
}

.region-item {
  padding: 5px 10px;
  background-color: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap; /* Empêche le texte de se casser */
}

.region-item.active {
  background-color: #016A40;
  color: white;
}

.group-regions {
  background-color: transparent; /* Fond transparent */
}

.no-region {
  color: red;
  margin-top: 10px;
}

/* Style de l'icône de chargement */
.fa-spinner {
  font-size: 24px;
  color: #016A40;
}

</style>
  
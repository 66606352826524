<template>
    <div v-if="isVisibleLoadFavorite" class="modal-backdrop-load-favorite">
      <div class="modal-content">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ModalLoadFavorites',
  props: {
    isVisibleLoadFavorite: {
      type: Boolean,
      required: true
    },
  },

  methods: {
    closeModalLoadFavorite() {
      this.$emit('close');
    },    
  },

  
};
</script>

<style scoped>
  .modal-backdrop-load-favorite {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ajout du z-index pour superposition */

  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 8px;
    width: 300px;
    font-size: 0.8rem;
    color: #000;
    font-size: 2rem;;
  }
  
</style>
<template>
  <Header />

  <div class="forgot-password-page">
    <h1>
      <i class="fas fa-arrow-left icon-back" @click="goToHome"></i>
      {{ $t('pageRecoveryPassword.pageTitle') }}
    </h1>
    <img src="@/assets/images/ewallet_23.png" alt="Mockup Image" class="mockup-image">
    
    <form @submit.prevent="handleSubmit" novalidate>
      <div class="input-container">
        <label for="emailOrPhone">
          <i class="fas fa-user icon-bg"></i>
          {{ $t('pageRecoveryPassword.phoneOrEmail') }}
        </label>
        <input type="text" v-model="form.emailOrPhone" id="emailOrPhone" @input="handleEmailOrPhoneInput" :placeholder="$t('pageRecoveryPassword.phoneOrEmail')" required />
      </div>

      <span v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </span>

      <button type="submit" :disabled="isLoading">
        <span v-if="isLoading">
          <i class="fas fa-spinner fa-spin"></i> {{ $t('pageRecoveryPassword.loading') }}
        </span>
        <span v-else-if="isNotEnding">
          <i class="fas fa-sync"></i> {{ $t('pageRecoveryPassword.reset') }}
        </span>
        <span v-else @click="goToHome">
          <i class="fas fa-home"></i> {{ $t('pageRecoveryPassword.accueil') }}
        </span>
      </button>

    </form>

    
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../shared/HeaderPage.vue';
import { SERVER_IP } from '../../config';

export default {
  name: 'ForgotPassword',
  components: {
    Header
  },

  data() {
    return {
      form: {
        emailOrPhone: '',
      },
      errorMessage: '',
      isLoading: false,
      isNotEnding: true,
    };
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;

      if (!this.validateInput()) {
        return; // Arrête la soumission si la validation échoue
      }

      try {
        const url = `${SERVER_IP}/resetpassword`;
        const response = await axios.post(url, { email: this.form.emailOrPhone });
        const responseData = response.data.success;
        this.errorMessage = '';

        if (responseData === 'Password Ready') {
          this.errorMessage = this.$t('pageRecoveryPassword.linkSent');
          this.isNotEnding = false;
        }
      } catch (error) {
        const serverError = error.response.data.error;
        if (serverError === 'no_account') {
          this.errorMessage = this.$t('pageLogin.noAccountFound');
        } else if (serverError === 'inactive_account') {
          this.errorMessage = this.$t('pageLogin.accountInactive')
        } else {
          this.errorMessage = this.$t('pageRecoveryPassword.errorRegistered');
        }
      } finally {
        this.isLoading = false;
      }


    },
    goToHome() {
      // Redirect to home page
      this.$router.push('/');
    },

    handleEmailOrPhoneInput() {
      this.errorMessage = ''; // Réinitialiser le message d'erreur
    },

    validateInput() {
      const username = this.form.emailOrPhone.trim(); // Supprimer les espaces avant et après
      
      // Vérifier si le champ est vide
      if (!username) {
        this.errorMessage = this.$t('pageLogin.enterPhone');
        this.isLoading = false; 
        return false; // Validation échoue
      }

      // Vérifier le format email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(username)) {
        this.errorMessage = ''; // Réinitialiser le message d'erreur
        return true; // Validation réussie pour email
      }

      // Vérifier le format numéro de téléphone
      const phoneRegex = /^509(?:31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;
      if (/^\d+$/.test(username)) {
        if (username.length === 8 && phoneRegex.test('509' + username)) {
          this.errorMessage = ''; // Réinitialiser le message d'erreur
          return true; // Validation réussie pour numéro à 8 chiffres
        } else if (username.length === 11 && phoneRegex.test(username)) {
          this.errorMessage = ''; // Réinitialiser le message d'erreur
          return true; // Validation réussie pour numéro à 11 chiffres avec préfixe 509 et code valide
        }
      }

      // Si aucun des formats n'est valide
      this.errorMessage = this.$t('pageLogin.enterValidPhone');
      this.isLoading = false; 
      return false; // Validation échoue
    },

  },
};
</script>

<style scoped>
.forgot-password-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: 50px;
  color: #FFFFFF;
}

.forgot-password-page h1 {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-back {
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  margin-right: 50px;
  color: black;
  font-size: 0.5em;
  cursor: pointer;
}

.mockup-image {
  width: 200px;
  height: auto;
  margin: 20px 0;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  text-align: left;
}

.input-container label {
  display: flex;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.input-container i {
  margin-right: 10px;
}

.input-container input {
  width: 95%;
  padding: 8px;
  font-size: 1em;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  transition: border-color 0.3s;
  color: #FFFFFF;
}

.input-container input:focus {
  border-color: #016A40;
  outline: none;
}

.error-message {
  display: flex;
  color: #FFFC00;
  margin-bottom: 5px;
  font-size: 0.9em;
  font-style: italic;
  text-align: right;
}

button {
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 30px;
}

button[disabled] {
  background-color: gray;
  cursor: not-allowed;
}

button:hover {
  background-color: #005934;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

</style>

<template>
    <div class="modal-overlay" @click.self="closeModalLoan">
      <div class="modal-content">
        <h2>Accès restreint</h2>
        <p>Vous n'avez pas encore accès à notre service de prêt.<br/><br/>
        Vous pouvez contacter le service à la clientèle pour en savoir plus sur les conditions d'éligibilité.<br/><br/>
        <a href="mailto:info@kobdirek.com">info@kobdirek.com</a>
        </p>
        <button class="close-btn" @click="closeModalLoan"><i class="fas fa-close"></i> Fermer</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalLoan',
    methods: {
        closeModalLoan() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Styles pour l'arrière-plan */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* arrière-plan semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Toujours devant les autres éléments */
  }
  
  /* Styles pour le modal */
  .modal-content {
    background-color: #ffffff; /* Arrière-plan blanc */
    color: #000000; /* Texte en noir */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 300px;
    width: 90%;
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
  }
  a{
    color: blue;
    font-style: italic;
    text-decoration: none;
  }
  
  /* Styles pour le bouton de fermeture */
  .close-btn {
    background-color: #f44336; /* Rouge pour indiquer la fermeture */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .close-btn:hover {
    background-color: #d32f2f;
  }
  </style>
  
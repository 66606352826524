<template>
    <Header />
    <PageHeader title="Jouer & Gagner" />
    <div class="list-game-page">
        <div class="image-grid">
            <img src="@/assets/images/games/mesi-chofe22.png" alt="Mockup d'interface de connexion" class="mockup-image" @click="showModalNoGame = true">
        </div>

        <ModalGameNotAvailable v-if="showModalNoGame" @close="showModalNoGame = false" />

    </div>

    <Footer :key="footerKey" />
</template>

<script>
  //import axios from 'axios';
  import Header from '../shared/HeaderPage.vue';
  import Footer from '../shared/FooterPage.vue';
  import PageHeader from '../shared/PageHeader.vue';

  import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

  import ModalGameNotAvailable from '../modals/ModalGameNotAvailable.vue';

  export default {
    name: 'ListGames',
    mixins: [refreshFooterMixin],
    components: {
      Header,
      PageHeader,
      Footer,
      ModalGameNotAvailable,
    },

    data() {
        return {
            showModalNoGame: false,
        };
    },

  }

</script>

<style scoped>
.list-game-page {
    max-width: 400px;
    margin: auto;
    margin-top: -20px;
    color: #FFFFFF;
    margin-bottom: 80px;
    padding: 10px 0px;
    display: flex;
    align-items: flex-start
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 images par ligne */
  gap: 10px; /* Espace entre les images */
}

.image-grid img {
  width: 100%; /* Pour que les images s'adaptent à la taille de leur conteneur */
  height: auto; /* Maintenir le ratio de l'image */
}

</style>
<template>
    <Header />
    <PageHeader :title="$t('CreatePin.title')" />

    <div class="pin-creation-container">
        
        <img src="@/assets/images/ewallet_34.png" alt="Mockup Image" class="mockup-image">

        <div class="pin-container" v-if="activeIndex === 0">
            <label for="pin-inputs" class="pin-label">Entrez un code PIN à 4 chiffres pour sécuriser votre compte</label>
            
            <div class="pin-inputs">
                <!-- Les inputs avec ref dynamique -->
                <input v-for="(digit, index) in pin" 
                       :key="index" 
                       v-model="pin[index]" 
                       maxlength="1"
                       :ref="'pin' + index" 
                       @input="onInput(index)" 
                       @keydown.backspace="onBackspace(index)" 
                       :type="isRevealed ? 'text' : 'password'" 
                       class="pin-box" 
                       inputmode="numeric" 
                       pattern="[0-9]*"
                       @keypress="isNumber($event)" />
                
                <!-- Icône pour révéler le code PIN -->
                <i class="fas fa-eye eye-icon" @mousedown="revealPin" @mouseup="hidePin" @mouseleave="hidePin"></i>
            </div>

            <div>
                <span class="error-message" v-if="pinError">{{ pinError }}</span>
            </div>
            
            <button class="submit-button" @click="validatePin">Valider le Code PIN <i class="fas fa-thumbs-up"></i></button>
        </div>

        <div class="pin-container" v-if="activeIndex === 1">

            <!-- Bouton de retour pour revenir aux premiers inputs -->
            <button class="back-button" @click="goBackIndex">
                <i class="fas fa-arrow-left"></i>
            </button>

            <label for="confirm-pin-inputs" class="pin-label">Confirmez le code PIN à 4 chiffres pour sécuriser votre compte</label>

            <div class="pin-inputs">
                <!-- Les inputs avec ref dynamique -->
                <input v-for="(digit, index) in confirmPin" 
                       :key="index" 
                       v-model="confirmPin[index]" 
                       maxlength="1"
                       :ref="'confirmPin' + index" 
                       @input="onConfirmInput(index)" 
                       @keydown.backspace="onConfirmBackspace(index)" 
                       :type="isConfirmRevealed ? 'text' : 'password'" 
                       class="pin-box" 
                       inputmode="numeric" 
                       pattern="[0-9]*"
                       @keypress="isNumber($event)" />

                <!-- Icône pour révéler le code PIN -->
                <i class="fas fa-eye eye-icon" @mousedown="revealConfirmPin" @mouseup="hideConfirmPin" @mouseleave="hideConfirmPin"></i>
            </div>

            <div>
                <span class="error-message" v-if="confirmPinError">{{ confirmPinError }}</span>
            </div>

            
            <button type="submit" class="submit-button" :disabled="loading" @click="validateConfirmPin">
                <span v-if="loading">
                    Enregistrement en cours <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else>
                    Enregistrer le Code PIN <i class="fas fa-check-circle"></i>
                </span>
            </button>
        </div>

        <div class="" v-if="activeIndex === 2">
            Félicitations !<br/><br/>
            Votre code PIN a bien été mis à jour.<br/><br/>
            Merci de sécuriser votre compte !<br/><br/>
            <button @click="goToHome" class="submit-button">
                {{ $t('pageRecoveryPassword.accueil') }} <i class="fas fa-home"></i>
            </button>
        </div>

    </div>
    
    <Footer :key="footerKey" />
</template>
  
<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import { checkAuth } from '../../utils/validationUtils';

import axios from 'axios';
import { SERVER_IP } from '../../config';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

export default {
    name: 'CreatePin',
    mixins: [refreshFooterMixin],
    components: {
        Header,
        PageHeader,
        Footer
    },

    created(){
        checkAuth();
    },
    data() {
        return {
            pin: ['', '', '', ''], // Stocke les 4 chiffres du PIN
            isRevealed: false,     // Permet de contrôler l'affichage du code PIN
            confirmPin: ['', '', '', ''], 
            isConfirmRevealed: false,
            pinError: '',
            confirmPinError: '',
            activeIndex: 0,
            loading: false,
        }
    },
    methods: {

        // Méthode pour revenir à la section de création du PIN
        goBackIndex() {
            this.activeIndex = 0;
            this.pin = ['', '', '', ''];
            this.confirmPin = ['', '', '', ''];
        },

        goToHome() {
            this.$router.push('/home');
        },

        // Empêche l'utilisateur d'entrer des lettres
        isNumber(event) {
            const charCode = event.keyCode ? event.keyCode : event.which;
            if (charCode < 48 || charCode > 57) { // 48-57 correspond aux chiffres 0-9
                event.preventDefault();
            }
        },

        // Lorsque l'utilisateur entre un chiffre dans un input
        onInput(index) {
            if (this.pin[index] && index < this.pin.length - 1) {
                // Si le champ est rempli, passer automatiquement au champ suivant
                this.$nextTick(() => {
                    const nextInput = this.$refs['pin' + (index + 1)][0];
                    if (nextInput) nextInput.focus();
                });
            }
            this.pinError = '';
        },

        onConfirmInput(index) {
            if (this.confirmPin[index] && index < this.confirmPin.length - 1) {
                // Si le champ est rempli, passer automatiquement au champ suivant
                this.$nextTick(() => {
                    const nextInput = this.$refs['confirmPin' + (index + 1)][0];
                    if (nextInput) nextInput.focus();
                });
            }
            this.confirmPinError = '';
        },

        // Gestion de la suppression d'un chiffre
        onBackspace(index) {
            if (!this.pin[index] && index > 0) {
                // Si le champ est vide après suppression, revenir au champ précédent
                this.$nextTick(() => {
                    const prevInput = this.$refs['pin' + (index - 1)][0];
                    if (prevInput) prevInput.focus();
                });
            } 
            this.pinError = '';
        },

        onConfirmBackspace(index) {
            if (!this.confirmPin[index] && index > 0) {
                // Si le champ est vide après suppression, revenir au champ précédent
                this.$nextTick(() => {
                    const prevInput = this.$refs['confirmPin' + (index - 1)][0];
                    if (prevInput) prevInput.focus();
                });
            } 
            this.confirmPinError = '';
        },

        // Révéler le PIN pendant 1 seconde
        revealPin() {
            this.isRevealed = true;
            setTimeout(() => {
                this.isRevealed = false;
            }, 3000); // Masque le code après 3 secondes
        },

        revealConfirmPin() {
            this.isConfirmRevealed = true;
            setTimeout(() => {
                this.isConfirmRevealed = false;
            }, 3000); // Masque le code après 3 secondes
        },

        hidePin() {
            this.isRevealed = false;
        },

        hideConfirmPin() {
            this.isConfirmRevealed = false;
        },

        // Validation du PIN
        validatePin() {
            if (this.pin.join('').length === 4) {
                this.activeIndex = 1;
            } else {
                this.pinError = 'Veuillez entrer un code PIN complet à 4 chiffres.';
            }
        },

        async validateConfirmPin() {
            this.loading = true;

            // Vérification si les deux PIN sont identiques
            if (this.confirmPin.join('') !== this.pin.join('')) {
                this.confirmPinError = 'Les codes PIN ne correspondent pas. Veuillez réessayer.';
                this.loading = false;
                return; // Arrête l'exécution si les PIN ne correspondent pas
            } 

            const url = `${SERVER_IP}/userRequest/update-userpin`;

            try {
                const userId = checkAuth(); // Supposant que checkAuth() renvoie l'ID utilisateur
                const response = await axios.post(url, { 
                    userId: userId,
                    codePin: this.confirmPin.join('') // Utilisation correcte de `this`
                });

                if (response.data.success) {
                    this.activeIndex = 2; // Change l'index si la réponse est réussie
                }
                
            } catch (error) {
                this.requestError = "Une erreur est survenue lors du traitement.";
            } finally {
                this.loading = false; // Réinitialise `loading` dans tous les cas
            }
        },

    },
}
</script>
  
<style scoped>
.pin-creation-container {
  max-width: 400px;
  margin: auto;
  padding: 0px;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;;
}

.mockup-image {
    width: 150px;
    height: auto;
}

.pin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pin-label {
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.pin-inputs {
  display: flex;
  gap: 10px;
  position: relative;
}

.pin-box {
  width: 50px;
  height: 50px;
  border: 2px solid #000103;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.eye-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #016A40;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
}

.submit-button:hover {
  background-color: #014f32;
}

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
}

.back-button {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #cccccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #000;
  display: flex;
  align-items: center;
}

.back-button i {
  margin-right: 5px;
}

.back-button:hover {
  background-color: #aaaaaa;
}

</style>

<template>
    <Header />
    <PageHeader title="Taux Du Jour" />

    <div class="daily-rates-page">
      <!-- DETAILS OFFICE -->
      <div class="office-details" v-if="officeDetails">
              <div class="details-container">
                <!-- Bloc de gauche -->
                <div class="left-block">
                  <div class="top-left">
                    <img :src="require('@/assets/images/icon_bank.png')" alt="Logo" class="office-logo" />
                    <h2 class="office-name">{{ officeDetails.name }}</h2>
                  </div>
                  <p class="office-address"><i class="fas fa-location-dot"></i> {{ officeDetails.address }}</p>
                </div>
                
                <!-- Bloc de droite -->
                <div class="right-block">
                  <div class="top-right">
                    <button class="close-button" @click="closeDetailsOffice">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                  <div class="bottom-right">
                    <h3 :class="{'open-status': officeDetails.isOpened === true, 'closed-status': officeDetails.isOpened === false}">
                      {{ stringStatusOffice(officeDetails.isOpened) }}
                    </h3>
                  </div>
                </div>
              </div>
              
              <div 
                v-for="(rates, index) in officeDetails.rates" 
                :key="index"
              >
              
                <!-- DETAILS CURRENCY -->
                <div class="currency-container">
                  <h2 class="currency-title">{{ rates.currency }} ({{nameCurrency(rates.currency)}})</h2>
                  <div class="rate-box">
                    <!-- Partie gauche (Achat) -->
                    <div class="rate-section">
                      <div class="rate-header">
                        <i class="fas fa-up-right-from-square rate-icon green"></i>
                        <span class="rate-type">Achat</span>
                      </div>
                      <div class="rate-value">{{ rates.buy }}</div>
                    </div>

                    <!-- Ligne séparatrice au milieu -->
                    <div class="separator"></div>

                    <!-- Partie droite (Vente) -->
                    <div class="rate-section">
                      <div class="rate-header">
                        <span class="rate-type">Vente</span>
                        <i class="fas fa-up-right-from-square rate-icon rotated red"></i>
                      </div>
                      <div class="rate-value">{{ rates.sell }}</div>
                    </div>
                  </div>

                  <div class="conversion-container">
                    <div class="inputs-wrapper">
                      <!-- Input de gauche avec l'add-on "HTG" à gauche -->
                      <div class="input-group left-addon">
                        <span class="input-addon">{{ rates.currency }}</span>
                        <input 
                          type="number" 
                          class="conversion-input" 
                          placeholder="0.00" 
                          v-model="rates.amountHTG" 
                          @input="convertToUSD(rates)" 
                        >
                      </div>

                      <!-- Icône d'échange au centre -->
                      <div class="exchange-icon">
                        <i class="fa-solid fa-right-left"></i>
                      </div>

                      <!-- Input de droite avec l'add-on "USD" à droite -->
                      <div class="input-group right-addon">
                        <input 
                          type="number" 
                          class="conversion-input" 
                          placeholder="0.00" 
                          v-model="rates.amountUSD" 
                          @input="convertToHTG(rates)" 
                        >
                        <span class="input-addon">HTG</span>
                      </div>

                    </div>

                    <!-- Texte de simulation de conversion -->
                    <div class="simulation-text">Simuler une conversion</div>
                  </div>


                </div>

              </div>

              <!-- BOTTOM -->
              <div class="parent-container">
                <!-- Div de gauche -->
                <div class="left-container">
                  <!-- Section Note -->
                  <div class="note-section">
                    <span>Note: </span>
                    <template v-for="star in 5" :key="star">
                      <i :class="star <= officeDetailsrating ? 'fas fa-star' : 'far fa-star'"></i>
                    </template>
                  </div>

                  <!-- Section des boutons -->
                  <div class="button-section">
                    <button @click="addToFavorite" class="btn" :class="{'btn-favorite': officeDetails.isFavorite === true, 'btn-non-favorite': officeDetails.isFavorite === false}"><i class="fas fa-heart"></i> Favoris</button>
                    <button @click="evaluateOffice" class="btn btn-rate"><i class="fas fa-thumbs-up"></i> Evaluer</button>
                  </div>
                </div>

                <!-- Div de droite -->
                <div class="right-container">
                  <div class="contact-title">Contact</div>
                  <div class="contact-info">
                    <i class="fa-solid fa-phone"></i> {{ officeDetails.phone_number || 'Non défini' }}
                  </div>
                  <div class="contact-info">
                    <i class="fa-solid fa-envelope"></i> {{ officeDetails.email || 'Non défini' }}
                  </div>
                  <div class="google-map-link">
                      <a :href="officeDetails.location" target="_blank" rel="noopener noreferrer">
                        Voir sur Maps
                      </a>
                  </div>

                </div>
              </div>

              <div class="close-container">
                <button class="cancel-btn" @click="closeDetailsOffice"> <i class="fa fa-times"></i> Fermer</button>
              </div>

          </div>



        <RegionSelector @region-selected="handleRegionSelection" />

        <div class="search-container">
            <!-- Input de recherche -->
            <div class="search-input">
                <input 
                    type="text" 
                    v-model="searchQuery" 
                    placeholder="Nom Bureau" 
                    @keyup.enter="searchOffice"
                />
                <!-- Icône de recherche à l'intérieur de l'input -->
                <button @click="fetchExchangeOffices" class="search-icon">
                    <i class="fas fa-search"></i>
                </button>
            </div>

            <!-- Bouton pour les filtres avec icône uniquement -->
            <button class="filter-btn"  @click="openFiltersModal">
                <i class="fas fa-sliders-h"></i>
            </button>

        </div>

        <div class="date-container">
          <div class="date-display" @click="openModalDate">
            <span class="date-text">{{ formattedDate }}</span>
            <span class="calendar-icon"><i class="fas fa-calendar-days"></i></span>
          </div>
        </div>

        <!-- Modal Date directement dans le composant -->
        <div v-if="showModalDate" class="modal-overlay" @click="closeModalDate">
          <div class="modal-content" @click.stop>
            <input 
              type="date" 
              ref="dateInput" 
              v-model="selectedDate" 
              @change="onDateChange"
              max="today"
            />
          </div>
        </div>



        <!-- List des bureaux -->
        <div>
          <!-- Spinner de chargement -->
          <div v-if="isLoading" class="loading-spinner">
            <i class="fas fa-spinner fa-spin"></i> Chargement des bureaux de change...
          </div>

          <!-- Liste des bureaux de change -->
          <div v-else>
            <!-- Si aucun bureau de change n'est disponible -->
            <div v-if="exchangeOffices.length === 0" class="no-offices-message">
                Aucun bureau de change disponible selon les paramètres sélectionnés.
            </div>

            <div v-else 
              v-for="(office, index) in exchangeOffices" 
              :key="index" 
              class="exchange-office" 
              @click="showDetails(office)"
            >
              <div class="office-left">
                <p class="office-name">{{ office.name }}</p>
              </div>
              <div class="office-right">
                <p class="currency-rate" v-if="office.rates.usd">USD: {{ office.rates.usd }}</p>
                <p class="currency-rate" v-if="office.rates.eur">EUR: {{ office.rates.eur }}</p>
                <p class="currency-rate" v-if="office.rates.can">CAN: {{ office.rates.can }}</p>
              </div>

            </div>
          </div>
        </div>

        <ModalAskLogin
          :isVisibleAskLogin="isAskLoginModalVisible"
          @close="closeAskLoginModal"
        />

        <ModalLoadFavorites
          :isVisibleLoadFavorite="isLoadfavoriteModalVisible"
          @close="closeLoadfavoriteModal"
        />
        <ModalEvaluate
          :isVisibleEvaluate="isEvaluateModalVisible"
          @close="closeEvaluateModal"
          @average-rating-updated="handleAverageRatingUpdate"
          :office-id="officeIDEvaluate"
        />

        <ModalFiltersRates
          :isVisible="isFiltersModalVisible"
          :regions="regions"
          :currencies="currencies"
          @apply-filters="handleApplyFilters"
          @close="closeFiltersModal"
        />
        

      </div>

    <Footer :key="footerKey" />
</template>

<script>
  //import axios from 'axios';
  import Header from '../shared/HeaderPage.vue';
  import Footer from '../shared/FooterPage.vue';
  import PageHeader from '../shared/PageHeader.vue';
  import RegionSelector from '../shared/regionSelector.vue';

  import axios from 'axios';
  import { SERVER_IP } from '../../config';
  import { checkAuth } from '@/utils/validationUtils';

  import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';
  import ModalFiltersRates from './ModalFiltersRates.vue';
  import ModalAskLogin from './ModalAskLogin.vue';
  import ModalLoadFavorites from './ModalLoadFavorites.vue';
  import ModalEvaluate from './ModalEvaluate.vue';
  
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() -4);

  export default {
    name: 'DailyRates',
    mixins: [refreshFooterMixin],
    components: {
      Header,
      PageHeader,
      RegionSelector,

      ModalFiltersRates,
      ModalAskLogin,
      ModalLoadFavorites,
      ModalEvaluate,

      Footer
    },

    data() {
      return {
        isLoading: true, // État de chargement
        exchangeOffices: [], // Tableau des bureaux de change
        selectedDate: currentDate.toISOString().split('T')[0], // Date actuelle
        showInput: true, // Pour contrôler l'affichage de l'input
        selectedRegionFromChild: null, // La région mise à jour depuis l'enfant
        today:'',
        
        isFiltersModalVisible: false,
        isAskLoginModalVisible: false,
        isLoadfavoriteModalVisible: false,
        isEvaluateModalVisible: false,
        officeIDEvaluate: 0,
        selectedFiltersEvaluate: {
          officeID: 1 // Remplacez cela par votre logique de filtrage
        },

        regions: [/* liste des régions */],
        currencies: ['USD', 'EUR', 'CAD'],
        selectedFilters :{
            selectedCurrencies: ['USD', 'EUR', 'CAN'], // Par défaut, toutes les devises sont sélectionnées
            isOpen: false, // Définit si les bureaux ouverts uniquement ou non
            isRecommended: false,
            isFavorite: false,
            selectedTypeRegion: 'Région', // Sélection par défaut de 'Région'
            selectedTypeOperation: 'Achat & Vente',
        },
        officeDetails: null,
        officeDetailsrating: 0,

      };
    },

    computed: {
      formattedDate() {
        // Diviser la date sélectionnée en composantes (année, mois, jour)
        const [year, month, day] = this.selectedDate.split('-');

        // Créer un objet Date avec les valeurs correctes (en ajustant le mois pour le format 0-indexé)
        const correctDate = new Date(year, month - 1, day);

        // Formater la date avec les options voulues
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return correctDate.toLocaleDateString('fr-FR', options);
      }

    },

    watch: {
      showModalDate(newVal) {
        if (newVal) {
          // Appliquer le focus dès que le modal est visible
          this.$nextTick(() => {
            const inputElement = this.$refs.dateInput;
            inputElement.focus(); // Fait en sorte que le champ soit actif immédiatement
            inputElement.click(); // Simule un clic pour activer la sélection de date
          });
        }
      },
    },

    methods: {
      async fetchExchangeOffices() {

        if (!this.selectedRegionFromChild || !this.selectedRegionFromChild.id) {
          this.isLoading = false;
          console.error('Région sélectionnée invalide');
          return;
        }

        this.isLoading = true;
        const url = `${SERVER_IP}/daily-exchanges/getOffices`;
        const userId = localStorage.getItem('tokenUser') === null ? 0 : checkAuth();
        try {
          // Simuler une requête API avec un délai
          const response = await axios.post(url, { 
              idRegion: this.selectedRegionFromChild.id,
              nameOffice: this.searchQuery,
              targetDate: this.selectedDate,

              currency: this.selectedFilters.selectedCurrencies, //: ['USD', 'EUR', 'CAN'], // Par défaut, toutes les devises sont sélectionnées
              horaire: this.selectedFilters.isOpen, //: false, // Définit si les bureaux ouverts uniquement ou non
              recommended: this.selectedFilters.isRecommended, //: false,
              favorite: this.selectedFilters.isFavorite, //: false,
              regionMode: this.getModeRegion(this.selectedFilters.selectedTypeRegion), //: 'Région', // Sélection par défaut de 'Région'
              typeOperation: this.getTypeOperation(this.selectedFilters.selectedTypeOperation), //: 'Achat & Vente',

              userId: userId,
          });

          if (response.status === 200) {
            this.exchangeOffices = response.data.officesWithRates || [];
          } else {
            this.exchangeOffices = [];
          }
          
        } catch (error) {
          this.exchangeOffices = [];
        } finally {
          this.isLoading = false;
        }

      },

      async showDetails(office) {
        this.isLoading = true;
        const url = `${SERVER_IP}/daily-exchanges/getDetailsOffice`;
        const userId = localStorage.getItem('tokenUser') === null ? 0 : checkAuth();
        
        try {

          const response = await axios.post(url, { 
              officeID: office.id,
              targetDate: this.selectedDate,
              currency: this.selectedFilters.selectedCurrencies,
              userId: userId
          });

          if (response.status === 200) {
            this.officeDetails = response.data.details;
            this.officeDetailsrating = this.officeDetails.rating;
          } else {
            this.officeDetails = null;
          }
          
        } catch (error) {
          this.officeDetails = null;
        } finally {
          this.isLoading = false;
        }
      },

      closeDetailsOffice() {
        this.officeDetails = null;
      },

      openModalDate() {
        this.showModalDate = true; // Ouvrir le modal
      },
      closeModalDate() {
        this.showModalDate = false; // Fermer le modal
      },
      onDateChange() {
        this.closeModalDate(); // Fermer le modal dès que la date est sélectionnée
        this.fetchExchangeOffices();
      },
      handleRegionSelection(selectedRegion) {
        // Mettre à jour le parent avec la région sélectionnée
        this.selectedRegionFromChild = selectedRegion;
        this.fetchExchangeOffices();
      },

      stringStatusOffice(status) {
        return status ? "OUVERT" : "FERME";
      },
      nameCurrency(curreny){
        switch (curreny) {
          case 'USD':
            return 'Dollar';
          case 'EUR':
            return 'Euro';
          case 'CAN':
            return 'Dollar Canadien';
          default:
            return 'Gourde';
        }
      },

      /********* Gestion office ***********/
      async addToFavorite() {
           if (localStorage.getItem('tokenUser') === null){
              this.isAskLoginModalVisible = true;
              return;
           }
           if (this.officeDetails.isFavorite) {
              return;
           }

           this.isLoadfavoriteModalVisible = true;

          const url = `${SERVER_IP}/daily-exchanges/setFavorites`;
          const userId = checkAuth();

          try {
            const response = await axios.post(url, { 
                officeID: this.officeDetails.officeID,
                userId: userId
            });

            if (response.status === 200) {
              this.officeDetails.isFavorite = true;
            }
            
          } catch (error) {
            this.isLoadfavoriteModalVisible = false;
          } finally {
            this.isLoadfavoriteModalVisible = false;
          }


      },

      async evaluateOffice() {
           if (localStorage.getItem('tokenUser') === null){
              this.isAskLoginModalVisible = true;
              return;
           }
           this.officeIDEvaluate = this.officeDetails.officeID; 
           this.isEvaluateModalVisible = true;
      },

      convertToUSD(rates) {
        // Convertir HTG à USD
        rates.amountUSD = (rates.amountHTG * rates.buy).toFixed(2);
      },
      convertToHTG(rates) {
        // Convertir USD à HTG
        rates.amountHTG = (rates.amountUSD / rates.sell).toFixed(2);
      },

      /************MODAL FILTERS ***************/
      openFiltersModal() {
        this.isFiltersModalVisible = true;
      },
      closeFiltersModal() {
        this.isFiltersModalVisible = false;
      },
      closeAskLoginModal() {
        this.isAskLoginModalVisible = false;
      },
      closeLoadfavoriteModal() {
        this.isLoadfavoriteModalVisible = false;
      },
      closeEvaluateModal() {
        this.isEvaluateModalVisible = false;
      },
      handleAverageRatingUpdate(newAverageRating) {
        this.officeDetailsrating = newAverageRating; // Mettre à jour la note moyenne
      },

      

      handleApplyFilters(filters) {
        this.selectedFilters = filters;
        this.isFiltersModalVisible = false;
        this.fetchExchangeOffices();
      },

      getModeRegion(mode) {
        switch (mode) {
          case 'Région':
            return 'regs';
          case 'Département':
            return 'dept';
          case 'Ville':
            return 'town';
          default:
            return 'regs';
        }
      },

      getTypeOperation(type) {
        switch (type) {
          case 'Achat & Vente':
            return '1';
          case 'Achat':
            return '2';
          case ' Vente':
            return '3';
          default:
            return '1';
        }
      },
      

    },

    mounted() {      
      this.today = currentDate.toISOString().substr(0, 10);
      localStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));

      if (this.selectedRegionFromChild) {
        this.fetchExchangeOffices();
      }
    },
    beforeUnmount() {
      if (localStorage.getItem('savedFilters')) {
        localStorage.removeItem('savedFilters');
      }
    },
    
  };

</script>

<style scoped>

.daily-rates-page {
  max-width: 400px;
  margin: auto;
  padding: 0px;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
}


/* SEARCH CONTAINER */
/* Style du conteneur */
.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Style de l'input de recherche */
.search-input {
  position: relative;
  flex-grow: 1;
  margin-right: 15px; /* Espace entre l'input et le bouton filters */
  max-width: 75%;
}

.search-input input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  background-color: white; /* Arrière-plan blanc */
  color: black; /* Texte noir */
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-input input::placeholder {
  color: lightgray; /* Placeholder gris clair */
}

/* Icône de recherche à l'intérieur de l'input */
.search-input .search-icon {
  position: absolute;
  right: -15%;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: black; /* Couleur de l'icône de recherche */
  cursor: pointer;
  font-size: 18px;
}

/* Bouton de filtre */
.filter-btn {
  padding: 10px;
  background-color: white; /* Arrière-plan blanc */
  color: black; /* Texte noir */
  border: none;
  border-radius: 5px;
  font-size: 16px; /* Taille de l'icône */
  cursor: pointer;

}

.filter-btn i {
  margin: 0;
}


/* CSS pour DATE */
.date-container {
  position: relative; /* Pour positionner l'input correctement */
  text-align: left; /* Alignement à gauche */
  margin: 10px 0px;
}

.date-display {
  display: flex; /* Pour aligner la date et l'icône */
  align-items: center; /* Centrer verticalement */
  border-bottom: 1px solid #FFF;
  font-style: italic;
  padding-bottom: 5px;
  width: fit-content;
}


.date-text {
  color: white; /* Texte blanc */
  margin-right: 10px; /* Espacement entre le texte et l'icône */
}

.calendar-icon {
  cursor: pointer; /* Curseur pointer pour indiquer cliquable */
  color: white; /* Couleur de l'icône en blanc */
  /* font-size: 1.5em; Taille de l'icône */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 200px;
  text-align: center;
}


/****** LIST DES BUREAUX */
.exchange-office {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* background-color: #f9f9f9; */
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 5px;
  transition: box-shadow 0.3s ease;
}

.exchange-office:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.office-left {
  flex: 1;
  text-align: left;
}

.office-name {
  /* font-size: 20px; */
  font-weight: bold;
  margin: 0;
  font-size: 1rem;
}
.office-left .office-name {
  color: #FFF;
}

.office-right {
  flex: 1;
  text-align: right;
}

.currency-rate {
  margin: 5px 0;
  /* font-size: 16px; */
  font-size: 0.7rem;
}

.details-hint {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #007bff;
  margin-top: 10px;
}

.details-hint p {
  margin: 0;
}

.loading-spinner {
  margin-top: 10%;
  font-size: 1.25rem;
}

/*************** DETAILS OFFICE *************************/
.office-details {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  /* Ajouter pour le superposer */
  position: fixed; /* Fixe l'élément par rapport à la fenêtre d'affichage */
  top: 50%; /* Centrage vertical */
  left: 50%; /* Centrage horizontal */
  transform: translate(-50%, -50%); /* Permet de centrer parfaitement */
  z-index: 1000; /* Le place au-dessus des autres éléments */
  
  /* Optionnel : Assurez-vous qu'il ne dépasse pas de la fenêtre */
  max-width: 350px;
  max-height: 90%;
  overflow: auto; /* Gère les débordements de contenu */
  overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
  scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
}


.details-container {
  display: flex;
  align-items: center; /* Centrer verticalement les blocs */
}

.left-block {
  width:80%; /* Les deux blocs occupent la moitié de la largeur */
}
.right-block {
  width: 20%; /* Les deux blocs occupent la moitié de la largeur */
  
}

.top-left {
  display: flex;
  align-items: center; /* Aligner logo et nom sur la même ligne */
}

.office-logo {
  width: 20px; /* Ajustez selon vos besoins */
  height: auto;
  margin-right: 8px; /* Espace entre le logo et le nom */
}

.office-name {
  font-size: 20px;
  color: black; /* Texte en noir */
  margin: 0; /* Pas de marge pour le nom */
}

.office-address {
  font-size: 12px; /* Taille de police plus petite */
  color: black; /* Texte en noir */
  font-style: italic; /* Italique pour l'adresse */
  margin-top: 4px; /* Un peu d'espace au-dessus de l'adresse */
  text-align: left;
}

.right-block {
  display: flex;
  flex-direction: column; /* Disposition verticale pour les éléments du bloc de droite */
  align-items: flex-end; /* Alignement à gauche pour les éléments du bloc de droite */
  text-align: right;
  padding-top: -50px;
}

/* .top-right {
  ,/* margin-bottom: 4px; Un peu d'espace sous le bouton de fermeture 
} */

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px; /* Ajustez selon vos besoins */
}

.open-status {
  color: green; /* Couleur verte pour 'OUVERT' */
  font-size: 20px; /* Taille de police plus grande */
  margin: 0; /* Pas de marge */
}

.closed-status {
  color: red; /* Couleur rouge pour 'FERME' */
  font-size: 20px; /* Taille de police plus grande */
  margin: 0; /* Pas de marge */
}


/* DETAILS CURRENCY */
.currency-container {
  background-color: #28292b;
  border-radius: 10px;
  padding: 5px 20px;
  text-align: center;
  margin-bottom: 5%;
}

.currency-title {
  color: white;
  margin-bottom: 20px;
}

.rate-box {
  background-color: #1f1d20;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 80%;
  padding: 20px;
  border-radius: 8px;
}

.rate-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.rate-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.rate-type {
  margin-right: 8px;
  font-size: 18px;
}

.rate-icon {
  font-size: 18px;
}
.green{
  color: green;
  margin-right: 8px;
}
.red {
  color: red;
}

.rate-value {
  font-size: 28px;
  font-weight: bold;
}

.separator {
  width: 2px;
  height: 80%;
  background-color: gray;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  bottom: 10%;
}

.rotated {
  transform: rotate(180deg);
}


.conversion-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

.inputs-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.input-group {
  position: relative;
  display: inline-block;
}

.input-addon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em; /* Add-on légèrement plus grand */
  color: #59595b;
  pointer-events: none; /* L'add-on ne doit pas bloquer l'interaction avec l'input */
}

/* Add-on à gauche pour "HTG" */
.left-addon .input-addon {
  left: 10px;
  margin-right: 2%;
}

/* Add-on à droite pour "USD" */
.right-addon .input-addon {
  right: 10px;
}

.conversion-input {
  padding: 10px;
  width: 130px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  box-sizing: border-box;
}

/* Padding spécial pour chaque input selon l'add-on */
.left-addon .conversion-input {
  padding-left: 50px; /* Laisser de l'espace pour l'add-on "HTG" à gauche */
}

.right-addon .conversion-input {
  padding-right: 40px; /* Laisser de l'espace pour l'add-on "USD" à droite */
}

/* Éliminer les bordures lors du focus */
.conversion-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

/* Icône d'échange */
.exchange-icon {
  margin: 0 10px;
  font-size: 20px;
  color: #59595b;
}

/* Texte pour la simulation de conversion */
.simulation-text {
  color: #59595b;
  font-size: 14px;
}


.parent-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
}

/* Style pour le conteneur de gauche */
.left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

/* Section Note */
.note-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.note-section span{
  color: #000;
}

.note-section i {
  color: gold;
  margin-left: 5px;
}

/* Section des boutons */
.button-section {
  display: flex;
  justify-content: space-between;
}

.button-section button {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Style spécifique pour les boutons */
.btn-favorite {
  background-color: gray;
  color: #1f1d20;
}
.btn-non-favorite {
  background-color: #f0ad4e;
  color: white;
}

.btn-rate {
  background-color: #5bc0de;
  color: white;
}

/* Style pour le conteneur de droite */
.right-container {
  flex: 1;
  padding: 10px;
  border: 2px solid green;
  border-radius: 10px;
}

.contact-title {
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #000;
}

.contact-info i {
  margin-right: 10px;
  color: green;
}

.contact-info i.fa-phone {
  color: green;
}

.contact-info i.fa-envelope {
  color: blue;
}

.google-map-link {
    margin-top: 5px; /* Espacement au-dessus */
    text-align: center; /* Centrer le texte */
}

.google-map-link a {
    color: #007bff; /* Couleur du lien */
    text-decoration: none; /* Pas de soulignement */
    font-weight: bold; /* Texte en gras */
}

.google-map-link a:hover {
    text-decoration: underline; /* Soulignement au survol */
}


.close-container {
  display: flex;
  justify-content: center; /* Centre le bouton horizontalement */
  margin-top: 15px;
}

.close-container button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
}



</style>
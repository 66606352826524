<template>
  <header class="header">
    <img src="@/assets/images/logo.webp" alt="Logo" class="logo">
    <h1 class="title">KOB - DIREK</h1>
  </header>
</template>

<script>
export default {
  name: 'HeaderPage',
};
</script>

<style scoped>
.header {
  background-color: #016A40;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.logo {
  height: 40px; /* Adjust as needed */
  margin-right: 10px;
}

.title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}
</style>

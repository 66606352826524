
import axios from 'axios';
import { SERVER_IP } from '../config';

export async function checkUpdateToken() {
    const token = localStorage.getItem('token'); // Récupérer le token depuis le stockage local
    let errorMessage = "";

      try {
        const response = await axios.post(`${SERVER_IP}/auth/update-token`, { 
                token 
            });
        
        // Si la réponse est réussie, nous récupérons et stockons le nouveau token
        if (response.data && response.data.success) {
          const newToken = response.data.token;
          localStorage.setItem('token', newToken);
          errorMessage = 'Token mis à jour avec succès.';
          
        } else {
          errorMessage = response.data.error;// || 'Erreur inattendue lors de la mise à jour du token.';
        }

      } catch (error) {
        errorMessage = 'Erreur lors de la mise à jour du token';
      } 
      return errorMessage;
}
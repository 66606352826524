<!-- src/components/SuccessRegister.vue -->
<template>
  <Header />
  <div class="success-register-container">
    <div class="success-register">
      <h3>Création de compte</h3>
      <img src="@/assets/images/confirmed-register.png" alt="confirmation enregistrement" class="mockup-image" />
      <p>Votre compte a été créé avec succès ! Merci de vérifier votre e-mail pour activer votre compte.</p>
      <button @click="goToHome" class="btn-home">
        <i class="fas fa-home"></i> Page d'accueil 
      </button>
    </div>
  </div>
</template>

<script>
import Header from '../shared/HeaderPage.vue';

export default {
  name: 'SuccessRegister',
  components: {
    Header
  },

  methods: {
    goToHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.success-register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  /*background-color: #f5f5f5; /* Couleur de fond de la page */
}

.success-register {
  text-align: center;
  max-width: 400px;
  /*background-color: #000103;; /* Couleur de fond de la boîte */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.success-register h3 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #FFFFFF;
}

.mockup-image {
  max-width: 200px;
  margin: 40px auto;
  display: block;
}

.success-register p {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #FFFFFF;
}

.btn-home {
  display: inline-flex;
  align-items: center;
  background-color: #016A40; /* Couleur de fond du bouton */
  color: white; /* Couleur du texte du bouton */
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-home i {
  margin-right: 10px;
}

.btn-home:hover {
  background-color: #005934; /* Couleur du bouton au survol */
}
</style>

<!-- src/views/PrivacyPolicy.vue -->
<template>
    <Header />

    <div class="privacy-policy">
      <div class="privacy-content">
        
        <h3>
            <i class="fas fa-info-circle"></i> Politique de Confidentialité
        </h3>

        <img src="@/assets/images/ewallet_12.png" alt="Mockup d'interface de connexion" class="mockup-image">

        <p>Nous vous remercions de votre intérêt pour notre plateforme de monnaie électronique. La confidentialité et la sécurité de vos données personnelles sont notre priorité. Cette politique de confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez nos services.</p>

        <h4 class="subtitle">1. Collecte des Informations</h4>

        <p>Nous collectons les informations que vous nous fournissez volontairement lors de votre inscription et de l'utilisation de nos services. Les types d'informations que nous collectons incluent, sans s'y limiter :</p>

        <ul>
        <li>Informations d'identification personnelles telles que votre nom, adresse e-mail, numéro de téléphone, adresse postale, date de naissance et informations de compte bancaire.</li>
        <li>Informations sur vos transactions financières effectuées via notre plateforme.</li>
        <li>Informations sur votre utilisation de nos services, telles que les pages visitées, les fonctionnalités utilisées et les préférences de compte.</li>
        <li>Informations recueillies via des cookies et technologies similaires pour améliorer votre expérience utilisateur et personnaliser le contenu et les annonces.</li>
        </ul>

        <h4 class="subtitle">2. Utilisation des Informations</h4>

        <p>Nous utilisons vos informations personnelles pour les finalités suivantes :</p>

        <ul>
        <li>Fournir et gérer nos services, y compris le traitement des transactions financières.</li>
        <li>Personnaliser et améliorer votre expérience utilisateur.</li>
        <li>Communiquer avec vous, y compris l'envoi de notifications importantes concernant les changements apportés à nos services ou à nos politiques.</li>
        <li>Répondre à vos demandes de service client et à vos questions.</li>
        <li>Prévenir la fraude et assurer la sécurité de nos utilisateurs et de nos services.</li>
        <li>Respecter nos obligations légales et réglementaires.</li>
        </ul>

        <h4 class="subtitle">3. Partage des Informations</h4>

        <p>Nous ne vendons pas, ne louons pas et ne partageons pas vos informations personnelles avec des tiers, sauf dans les circonstances suivantes :</p>

        <ul>
        <li>Avec votre consentement explicite.</li>
        <li>Pour répondre aux exigences légales, telles que les mandats de perquisition, les assignations à comparaître ou les autres demandes gouvernementales ou juridiques.</li>
        <li>Avec des prestataires de services tiers qui agissent en notre nom et qui sont soumis à des obligations contractuelles pour protéger vos informations personnelles.</li>
        <li>Pour protéger nos droits, notre sécurité et ceux de nos utilisateurs.</li>
        </ul>

        <img src="@/assets/images/ewallet_09.png" alt="Mockup d'interface de connexion" class="mockup-image">

        <h4 class="subtitle">4. Sécurité des Données</h4>

        <p>Nous mettons en œuvre des mesures de sécurité techniques, administratives et physiques pour protéger vos informations personnelles contre tout accès non autorisé, toute divulgation, altération ou destruction.</p>

        <h4 class="subtitle">5. Vos Choix et Droits</h4>

        <p>Vous pouvez accéder, mettre à jour et corriger vos informations personnelles en vous connectant à votre compte sur notre plateforme. Vous avez également le droit de demander la suppression de vos données personnelles, sous réserve de nos obligations légales de conservation des données.</p>

        <img src="@/assets/images/ewallet_06.png" alt="Mockup d'interface de connexion" class="mockup-image">

        <h4 class="subtitle">6. Modifications de la Politique de Confidentialité</h4>

        <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Nous vous informerons des modifications importantes par des moyens appropriés, tels que l'affichage d'une notification sur notre site web ou l'envoi d'un e-mail. L'utilisation continue de nos services après de telles modifications constitue votre acceptation de la politique de confidentialité révisée.</p>

        <h4 class="subtitle">7. Contact</h4>

        <p>Si vous avez des questions concernant notre politique de confidentialité ou la manière dont nous traitons vos informations personnelles, veuillez nous contacter à l'adresse suivante :</p>

        <p>Kob Direk<br>
        #26, Impasse Bethany, Petite Place Cazeau,<br>
        Delmas, Port-au-Prince, Ouest, Haïti<br>
        E-mail : <a href="mailto:info@kobdirek.geekzonehaiti.net">info@kobdirek.geekzonehaiti.net</a><br>
        Téléphone : (509) 4040-9077</p>


      </div>
  
      <!-- Bouton de retour -->
      <button @click="goBack" class="btn-back">
        <i class="fas fa-arrow-left"></i> Retour
      </button>

    </div>
</template>
  
<script>
  
    import Header from '../shared/HeaderPage.vue';
    export default {
      name: "PrivacyPolicy",
      components: {
        Header
      },

      methods: {
        goBack() {
          this.$router.go(-1); // Retourne à la page précédente
        },
      },
    };

</script>
  
  <style scoped>
    .privacy-policy {
    max-width: 400px;
    margin: auto;
    padding: 0px 10px;
    text-align: justify;
    color: #ffffff;
  }

  .privacy-content {
  margin-bottom: 80px; /* Pour que le contenu ne soit pas coupé par le bouton */
  }
  h3 i{
      margin-right: 10px;
  }
  h4{
      font-weight: normal;
  }

  .mockup-image {
    max-width: 200px;
    margin: 20px auto;
    display: block;
  }

  .subtitle {
      text-decoration: underline;
      text-decoration-thickness: 2px; /* Ajustez l'épaisseur du soulignement */
      text-underline-offset: 5px; /* Ajustez l'espacement entre le texte et le soulignement */
  }


  .btn-back {
  position: sticky;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  }

  .btn-back i {
  margin-right: 10px;
  }

  .btn-back:hover {
  background-color: #005934;
  }

  a, .privacy-link {
  color: #178ff5;
  text-decoration: none;
  }

  a:hover, .privacy-link:hover {
  color: #106bb4;
  }
</style>
  
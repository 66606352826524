
export const refreshFooterMixin = {
    data() {
        return {
            footerKey: 0, // Cle utilisee pour forcer le rafraichissement
        };
    },

    mounted() {
        this. startFooterRefresh();
    },

    methods: {
        startFooterRefresh() {
            setInterval(() => {
                this.refreshFooter();
            }, 15000); //30000ms = 30 secondes
        },
        refreshFooter() {
            this.footerKey += 1; //change la cle pour rafraichir footer
        },
    },
};
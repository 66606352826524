<template>
    <Header />
    <PageHeader title="Gestion des épargnes" :goBack="customGoBack" />

    <div class="manage-savings-page">
        
        <div class="btn-large-container">
            <button class="btn-add-saving" @click="addSaving">
                Placer une épargne <i class="fas fa-plus-circle"></i>
            </button>
        </div>

        <div class="head-menu">
            <div class="child-head-menu">
                <label for="status">
                    <i class="fas fa-info-circle icon-bg"></i> Statut
                </label>
                <select id="status" v-model="menu.status" @change="updateFilters">
                    <option value="all">Tous</option>
                    <option value="active">Actif</option>
                    <option value="closed">Terminé</option>
                </select>
            </div>

            <div class="child-head-menu">
                <label for="currency">
                    <i class="fas fa-coins icon-bg"></i> Devise
                </label>
                <select id="currency" v-model="menu.currency" @change="updateFilters">
                    <option value="all">Tous</option>
                    <option value="HTG">HTG</option>
                    <option value="USD">USD</option>
                </select>
            </div>

            <div class="child-head-menu">
                <label for="timing">
                    <i class="fas fa-calendar icon-bg"></i> Durée
                </label>
                <select id="timing" v-model="menu.timing" @change="updateFilters">
                    <option value="0">Tous</option>
                    <option value="1">1 Mois</option>
                    <option value="3">3 Mois</option>
                    <option value="6">6 Mois</option>
                    <option value="12">12 Mois</option>
                </select>
            </div>
        </div>

        <div class="loading-fetch" v-if="loadingFetch">
            <i class="fas fa-spinner fa-spin"></i>
        </div>

        <div v-else>
            <div v-if="savings.length" class="info-card-container">
                <!-- Contenu des savings -->
                
                <div v-for="(saving, index) in savings" 
                    :key="index" 
                    class="info-card"
                    :class="{
                        'border-almost-due': saving.almostDue,
                        'border-closed': saving.status === 'closed'
                    }"
                >
                    
                        <div class="info-row">
                            <span class="label">ID:</span>
                            <span class="value">#{{saving.code}}</span>
                        </div>

                        <div class="info-row">
                            <span class="label">Montant:</span>
                            <span class="value">{{saving.amount}} {{saving.currency_code}}</span>
                        </div>

                        <div class="info-row">
                            <span class="label">Taux:</span>
                            <span class="value">{{saving.interest_rate}}%</span>
                        </div>
                        
                        <div class="info-row">
                            <span class="label">Date de dépôt:</span>
                            <span class="value">{{formatDate(saving.start_date)}}</span>
                        </div>
                        
                        <div class="info-row">
                            <span class="label">Date de fin:</span>
                            <span class="value">{{formatDate(saving.maturity_date)}}</span>
                        </div>
                        
                        <div class="info-row">
                            <span class="label">Statut:</span>
                            <span class="value"
                                :class="{
                                    'status-active': saving.status === 'active',
                                    'status-closed': saving.status === 'closed',
                                    'status-cancelled': saving.status === 'cancelled'
                                }"
                            >
                                {{ formatStatus(saving.status) }}
                            </span>
                        </div>

                        <div class="info-row-button">
                            <button class="btn-add-saving" @click="downloadReceipt(saving)" :disabled="saving.isDownloading">
                                <span v-if="saving.isDownloading">
                                    Téléchargement en cours <i class="fas fa-spinner fa-spin"></i>
                                </span>
                                <span v-else>
                                    Téléchargez ici <i class="fas fa-download"></i>
                                </span>
                            </button>
                        </div>

                        

                </div>

            </div>

            <div class="no-result-fetch" v-else>
                <span>Aucun résultat ne correspond à vos critères de recherche.</span>
            </div>
        </div>


        <!-- Bloc de pagination -->
        <div class="pagination-container">
            <div class="pagination" v-if="totalPages > 1">
                <button @click="prevPage" :disabled="currentPage === 1">
                    <i class="fa-solid fa-angles-left"></i>
                </button>
                <span> {{ currentPage }} / {{ totalPages }} </span>
                <button @click="nextPage" :disabled="currentPage === totalPages">
                    <i class="fa-solid fa-angles-right"></i>
                </button>
            </div>
        </div>
    </div>

    <Footer :key="footerKey" />
    
</template>


<script>
    import Header from '../shared/HeaderPage.vue';
    import Footer from '../shared/FooterPage.vue';
    import PageHeader from '../shared/PageHeader.vue';
    import axios from 'axios';
    import { SERVER_IP } from '../../config';

    import { checkAuth } from '@/utils/validationUtils';

    import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';


    export default {
        name: 'ManageSavings',
        mixins: [refreshFooterMixin],

        components: {
            Header,
            PageHeader,
            Footer,

        },
        created(){
            checkAuth();
        },
        mounted() {     // Nombre total de pages
            this.fetchSavings(); // Appel à l'API pour récupérer les économies une fois le composant monté
        },
        
        data() {
            return {
                menu: {
                    status: 'all',
                    currency: 'all',
                    timing: 0,
                },
                savings: [],
                loadingFetch: true,
                currentPage: 1,     // Page courante
                itemsPerPage: 4,   // Nombre d'éléments par page
                totalPages: 1,       // Nombre total de pages
                isDownloading: false,
            };
        },

        methods: {
            addSaving(){
                this.$router.push('/place-savings');
            },

            formatDate(dateString) {
                const dateObject = new Date(dateString);
                const day = String(dateObject.getDate()).padStart(2, '0'); // Obtenir le jour
                const monthNames = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'];
                const month = monthNames[dateObject.getMonth()]; // Obtenir le mois abrégé
                const year = dateObject.getFullYear(); // Obtenir l'année

                return `${day} ${month} ${year}`; // Retourner le format final
            },

            formatStatus(status) {
                switch (status) {
                    case 'active':
                        return 'Active';
                    case 'closed':
                        return 'Terminé';
                    case 'cancelled':
                        return 'Annulé';
                    default:
                        return 'Non défini';
                }
            },


            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.fetchSavings();  // Charger les données de la nouvelle page
                }
            },
            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.fetchSavings();  // Charger les données de la nouvelle page
                }
            },

            updateFilters() {
                this.currentPage = 1;
                this.fetchSavings();
            },

            async fetchSavings() {
                this.loadingFetch = true;
                const url = `${SERVER_IP}/savings/getSavings`;
                this.savings = [];

                try {
                    const userId = checkAuth();
                    const response = await axios.post(url, {
                        userId: userId,
                        status: this.menu.status,
                        currency: this.menu.currency,
                        timing: this.menu.timing,
                        currentPage: this.currentPage, 
                        itemsPerPage: this.itemsPerPage,
                    });

                    if (response.status === 200 ) {
                        // On met à jour les données locales après réception de l'API
                        this.savings = response.data.data.savings;   // Tableau des économies
                        this.totalPages = response.data.data.totalPages;   // Total des pages
                    }
                    
                } catch (error) {
                    console.log(error.response.data.message);
                } finally {
                    this.loadingFetch = false;
                }
            },

            async downloadReceipt(saving) {
                saving.isDownloading = true;
              try {
            

                // Envoi de la requête POST à l'API pour générer le PDF
                const url = `${SERVER_IP}/savings/download-receipt`;
                const userId = checkAuth();
                const response = await axios.post(
                  url, 
                  { userId: userId, savingId: saving.id },
                  { responseType: 'blob' }  // Indiquer que la réponse est un fichier (blob)
                );

                // Vérification si la réponse contient un fichier PDF
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                
                // Créer un lien temporaire et le simuler pour le téléchargement
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `DEPOT-${saving.id}.pdf`;  // Nom du fichier à télécharger
                document.body.appendChild(link);
                link.click();  // Simuler un clic pour télécharger le fichier
                document.body.removeChild(link);  // Nettoyage après le téléchargement

              } catch (error) {
                console.error('Erreur lors du téléchargement du PDF:', error);
              } finally {
                saving.isDownloading = false;
              }
            },


        },

    }

</script>

<style scoped>

.manage-savings-page {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
font-size: 0.8rem;
margin-bottom: 5.5%;
}

.btn-large-container {
  text-align: center;
  margin-top: 20px;
}
.btn-add-saving {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: 100%;
}

.btn-add-saving:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-add-saving:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

.head-menu {
    display:flex;
    align-items: center;
    width: 100%;
    margin:5% 0%;
}
.child-head-menu {
    padding: 0px 5px;
    width: 33.33%;
}

label {
  margin-bottom: 0.5em;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

select {
  padding: 0.5em 0;
  font-size: 1em;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

select:focus {
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}

option {
    color: #000;
}


/*************** INFO SUR PLACEMENT********************/

.loading-fetch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.no-result-fetch {
    text-align: center;
    margin-top: 20px;
    color: #666;
    font-size: 16px;
}

.info-card-container {
    display: flex; /* Utiliser flexbox pour le conteneur */
    flex-wrap: wrap; /* Permettre le wrapping des cartes */
    justify-content: space-between; /* Espace entre les cartes */
    padding: 0px; /* Optionnel : Ajoute du padding autour des cartes */
    width: 100%;
}

.info-card {
    background-color: #FFF;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 10px;
    width: calc(50% - 10px); /* Trois cartes par ligne, moins le padding */
    margin: 10px 5px; /* Espace entre les cartes */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; /* Inclut padding et border dans la taille de la carte */
}
.border-almost-due {
    border-color: orange; /* Couleur de bordure pour presque à terme */
}

.border-closed {
    border-color: red; /* Couleur de bordure pour clos */
}

.info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.info-card .label {
    font-weight: bold;
    color: #333;
}

.info-card .value {
    color: #555;
}

.info-card .status-active {
    color: green;
    font-weight: bold;
}
.status-closed {
    color: red; /* Couleur pour le statut fermé */
    font-weight: bold;
}

.status-cancelled {
    color: orange; /* Couleur pour le statut annulé */
    font-weight: bold;
}

.info-row-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}



/********************* PAGINATION **********************/
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5%;
}

.pagination {
    display: flex;
    align-items: center;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination span {
    margin: 0 10px;
    font-size: 12px;
    font-weight: bold;
}


</style>
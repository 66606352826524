<template>
    <Header />
    <PageHeader title="Modifier mot de passe" />

    <div class="changepassword-page">

        <img src="@/assets/images/ewallet_22.png" alt="Mockup" class="mockup-image">

        <div v-if="activeIndex === 0">
          <form @submit.prevent="handleSubmit" novalidate>

            <div class="form-group">
                <label for="currentPassword">
                  <i class="fas fa-lock icon-bg"></i> Mot de passe actuel
                </label>
                <div class="password-container">
                  <input
                    :type="showCurrentPassword ? 'text' : 'password'"
                    id="currentPassword"
                    v-model="form.currentPassword"
                    @input="handleCurrentPasswordInput"
                    placeholder="Mot de passe actuel"
                    required
                  />
                  <i
                    :class="showCurrentPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    @click="toggleCurrentPasswordVisibility"
                    class="toggle-current-password-visibility"
                  ></i>
                </div>
                <span v-if="currentPasswordError" class="error-message">{{ currentPasswordError }}</span>
            </div>

            <div class="form-group">
                <label for="newPassword">
                  <i class="fas fa-shield-alt icon-bg"></i> Nouveau mot de passe
                </label>
                <div class="password-container">
                  <input
                    :type="showNewPassword ? 'text' : 'password'"
                    id="newPassword"
                    v-model="form.newPassword"
                    @input="handleNewPasswordInput"
                    placeholder="Nouveau mot de passe"
                    required
                  />
                  <i
                    :class="showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    @click="toggleNewPasswordVisibility"
                    class="toggle-new-password-visibility"
                  ></i>
                </div>
                <span v-if="newPasswordError" class="error-message">{{ newPasswordError }}</span>
            </div>

            <div class="form-group">
                <label for="confirmPassword">
                  <i class="fas fa-circle-check icon-bg"></i> Confirmer nouveau mot de passe
                </label>
                <div class="password-container">
                  <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    id="confirmPassword"
                    v-model="form.confirmPassword"
                    @input="handleConfirmPasswordInput"
                    placeholder="Confirmer nouveau mot de passe"
                    required
                  />
                  <i
                    :class="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    @click="toggleConfirmPasswordVisibility"
                    class="toggle-confirm-password-visibility"
                  ></i>
                </div>
                <span v-if="confirmPasswordError" class="error-message">{{ confirmPasswordError }}</span>
            </div>

            <button type="submit" class="btn-submit" :disabled="loading">
              <span v-if="loading">
                Opération en cours... <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span v-else>
                Mettre à Jour <i class="fas fa-sync-alt"></i>
              </span>
            </button>

            </form>
        </div>

        <div v-if="activeIndex === 1">
          <p>Le changement de votre mot de passe a été effectué avec succès ! Merci de continuer à utiliser notre service en toute sécurité.</p>
          <button @click="goBack" class="btn-submit">
            <i class="fas fa-arrow-circle-left"></i> Retour 
          </button>
        </div>
        

    </div>

    <Footer :key="footerKey" />
</template>


<script>
  import Header from './shared/HeaderPage.vue';
  import Footer from './shared/FooterPage.vue';
  import PageHeader from './shared/PageHeader.vue';

  import axios from 'axios';
  import { SERVER_IP } from '../config';

  import { validatePassword, validateConfirmPassword, checkAuth } from '@/utils/validationUtils';

  import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

  export default {
    name: 'ChangePassword',
    mixins: [refreshFooterMixin],
    components: {
      Header,
      PageHeader,
      Footer
    },
    data() {
      return {
        activeIndex: 0,

        form: {
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
          loading: false,
        },
        currentPasswordError: '', // Variable pour le message d'erreur du username
        newPasswordError: '', // Variable pour le message d'erreur du password
        confirmPasswordError: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
      };
    },

    methods: {

      handleCurrentPasswordInput() {
          this.currentPasswordError = ""; // Masquez le message d'erreur
      },
      toggleCurrentPasswordVisibility() {
        this.showCurrentPassword = !this.showCurrentPassword;
      },

      handleNewPasswordInput() {
          this.newPasswordError = ""; // Masquez le message d'erreur
      },
      toggleNewPasswordVisibility() {
        this.showNewPassword = !this.showNewPassword;
      },

      handleConfirmPasswordInput() {
          this.confirmPasswordError = ""; // Masquez le message d'erreur
      },
      toggleConfirmPasswordVisibility() {
        this.showConfirmPassword= !this.showConfirmPassword;
      },

      goBack() {
        window.history.back();
      },

      scrollToError(field) {
          const element = document.getElementById(field);
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
          this.loading = false;
      },

      async handleSubmit() {
          this.loading = true; // Début du chargement

          let validationResult = validatePassword(this.form.currentPassword);
          if(!validationResult.isValid && !this.form.currentPassword){
              this.currentPasswordError = validationResult.errorMessage;
              this.scrollToError('currentPassword');
              return;
          }

          validationResult = validatePassword(this.form.newPassword);
          if(!validationResult.isValid){
              this.newPasswordError = validationResult.errorMessage;
              this.scrollToError('newPassword');
              return;
          }

          validationResult = validateConfirmPassword(this.form.newPassword, this.form.confirmPassword);
          if(!validationResult.isValid){
              this.confirmPasswordError = validationResult.errorMessage;
              this.scrollToError('confirmPassword');
              return;
          }

          const url = `${SERVER_IP}/userRequest/update-password`;
          try {
                const userId = checkAuth();
                const response = await axios.post(url, { 
                    password: this.form.currentPassword,
                    newPassword: this.form.newPassword,
                    userId: userId
                });
                if (response.data.success){
                    this.loading = false;
                    this.activeIndex = 1;
                }
            } catch (error) {
              if(error.response.data.error === 'invalid-password') {
                this.newPasswordError =  'Vous ne pouvez pas utiliser ce mot de passe, il est trop risqué.';
                this.scrollToError('newPassword');
              }
              if(error.response.data.error === 'incorrect-password') {
                this.currentPasswordError =  'Votre mot de passe actuel doit être incorrecte';
                this.scrollToError('currentPassword');
              }
            } finally {
                this.loading = false;
            }
      },

    }
    

  }

</script>

<style scoped>

.changepassword-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: -20px;
  color: #FFFFFF;
  margin-bottom:80px;
}

.mockup-image {
  width: 150px;
  height: auto;
  margin: 2px 0;
}


.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group i{
  margin-right: 10px;
}

.form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-group input {
  width: 95%;
  padding: 8px;
  font-size: 1em;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  transition: border-color 0.3s;
  color: #FFFFFF;
}

.form-group input:focus {
  border-color: #016A40;
  outline: none;
}

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
}


.btn-submit {
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.btn-submit:hover {
  background-color: #005934;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

.password-container {
  position: relative;
  width: 100%;
}
.toggle-current-password-visibility {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(105, 105, 105);
}
.toggle-new-password-visibility {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(105, 105, 105);
}
.toggle-confirm-password-visibility {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(105, 105, 105);
}

</style>
<template>
    <Header />

    <div class="recovery-password-page">
      <h1>
        <i class="fas fa-arrow-left icon-back" @click="goBack"></i>
        Changer mot de passe
      </h1>
      <img src="@/assets/images/recovery-password.png" alt="Mockup Image" class="mockup-image">

      <form @submit.prevent="handleSubmit" v-if="activeIndex === 0"  novalidate>
       
        <div class="input-container">
            <label for="newPassword">
              <i class="fas fa-lock icon-bg"></i> Nouveau mot de passe
            </label>
            <div class="password-container">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password"
                v-model="form.password"
                @input="handlePasswordInput"
                placeholder="Nouveau mot de passe"
                required
              />
              <i
                :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                @click="togglePasswordVisibility"
                class="toggle-password-visibility"
              ></i>
            </div>
            <span v-if="passwordError" class="error-message">{{ passwordError }}</span>
        </div>

        <div class="input-container">
            <label for="confirmPassword">
              <i class="fas fa-shield-alt icon-bg"></i> Confirmer le mot de passe
            </label>
            <div class="confirm-password-container">
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                id="password"
                v-model="form.confirmPassword"
                @input="handleConfirmPasswordInput"
                placeholder="Confirmer le mot de passe"
                required
              />
              <i
                :class="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                @click="toggleConfirmPasswordVisibility"
                class="toggle-confirm-password-visibility"
              ></i>
            </div>
            <span v-if="confirmPasswordError" class="error-message">{{ confirmPasswordError }}</span>
        </div>

        <button type="submit" :disabled="isLoading">
          <span v-if="isLoading">
            <i class="fas fa-spinner fa-spin"></i> Chargement...
          </span>
          <span v-else><i class="fas fa-key"></i> Réinitialiser</span>
        </button>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </form>

      <div v-if="activeIndex === 1">
          <h5>Mot de passe mis à jour avec succès !</h5>
          <p>
            Votre nouveau mot de passe a été enregistré. Vous allez être redirigé vers la page de connexion pour vous connecter avec vos nouvelles informations.
          </p>

          <button @click="goToHome" class="btn-submit">
              {{ $t('pageRecoveryPassword.accueil') }} <i class="fas fa-home"></i>
          </button>
      </div>


    </div>
</template>
  
<script>
  import axios from 'axios';
  import Header from '../shared/HeaderPage.vue';
  import { SERVER_IP } from '../../config';

  export default {
    name: 'RecoveryPassword',
    components: {
      Header
    },

    data() {
      return {
        form:{
            password: '',
            confirmPassword: '',
        },
        errorMessage: '',
        isLoading: false,
        passwordError: "", // Ajoutez cette propriété pour suivre l'état de l'erreur password
        confirmPasswordError: "", // Ajoutez cette propriété pour suivre l'état de l'erreur confirmPassword
        showPassword: false,
        showConfirmPassword: false,
        activeIndex: 1,
      };
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const token = to.query.token; // Utilisez 'to.query.token' pour accéder au token
            const url = `${SERVER_IP}/auth/verify-token/${token}`;
            const response = await axios.get(url);
            console.log(response.data); // Vérifiez ce que renvoie la réponse
            
            if (response.data.valid) {
                next();
            } else {
                next('/');
            }
        } catch (error) {
            console.error('Erreur lors de la vérification du token :', error);
            next('/');
        }
    },
    methods: {
        handlePasswordInput() {
            this.passwordError = ""; // Masquez le message d'erreur
            this.errorMessage =  "";
        },
        togglePasswordVisibility() {
          this.showPassword = !this.showPassword;
        },

        handleConfirmPasswordInput() {
            this.confirmPasswordError = ""; // Masquez le message d'erreur
            this.errorMessage = "";
        }, 
        toggleConfirmPasswordVisibility() {
          this.showConfirmPassword = !this.showConfirmPassword;
        },
        goToHome() {
          this.$router.push('/home');
        },

      async handleSubmit() {
        this.isLoading = true;

        if (!this.validatePassword()) {
            this.isLoading = false;
            return;
        }
        if (!this.validateConfirmPassword()) {
            this.isLoading = false;
            return;
        }

        try {
            const url = `${SERVER_IP}/auth/reset-password`;
            const response = await axios.post(url, {
                token: this.$route.query.token,
                password: this.form.password,
            });

            // Réinitialisation réussie
            if (response.data.success) {
                this.errorMessage = ''; // Réinitialisation de l'éventuelle erreur précédente
                this.activeIndex = 1;
            } else {
                // Gestion d'autres réponses possibles (par exemple, gestion des erreurs spécifiques)
                this.errorMessage =  'Une erreur s\'est produite lors de la réinitialisation du mot de passe.';
            }
        } catch (error) {
            if(error.response.data.error === 'no-account')
              this.errorMessage =  'Le lien de réinitialisation est invalide ou a expiré.';
            else if(error.response.data.error === 'invalid-password') {
              this.errorMessage =  'Vous ne pouvez pas utiliser ce mot de passe, il est trop risqué.';
            }
              
        } finally {
            this.isLoading = false;
        }

      },
      goBack() {
        this.$router.go(-1); // Retour à la page précédente
      },
      validatePassword() {
        const password = this.form.password;

        if (!password) {
            this.passwordError = "Le champ Mot de passe est requis.";
            return false;
        }

        // Vérification de la longueur du mot de passe
        const hasMinimumLength = password.length >= 8;
        // Vérification des critères de sécurité
        const hasDigit = /\d/.test(password);
        const hasLetter = /[a-zA-Z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        // Regroupement des vérifications
        if (!hasMinimumLength || !hasDigit || !hasLetter || !hasSpecialChar) {
            this.passwordError = "Votre mot de passe n'est pas assez fort. Il doit contenir au moins 8 caractères, incluant une majuscule, une minuscule, un chiffre et un symbole spécial (ex: !, @, #, $). Veuillez réessayer.";
            return false;
        }

        return true;

    },

    validateConfirmPassword() {
      if (!this.form.confirmPassword) {
        this.confirmPasswordError = "Veuillez confirmer votre mot de passe.";
        return false;
      }
      if (this.form.confirmPassword !== this.form.password) {
        this.confirmPasswordError = "Les mots de passe ne correspondent pas.";
        return false;
      }
      return true;
    },
    },
  };
</script>
  
<style scoped>
  .recovery-password-page {
    text-align: center;
    max-width: 400px;
    margin: auto;
    margin-top: 50px;
    color: #FFFFFF;
  }
  
  .recovery-password-page h1 {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon-back {
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    margin-right: 30px;
    color: black;
    font-size: 0.5em;
    cursor: pointer;
  }
  .mockup-image {
    width: 200px;
    height: auto;
    margin: 20px 0;
  }

  .icon-bg {
    background-color: #016A40;
    color: white;
    padding: 5px; /* Taille des icônes réduite */
    border-radius: 8px; /* Coins arrondis */
    margin-right: 10px;
    font-size: 0.6em; /* Réduire la taille des icônes */
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 0.9em;
    }
  
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .input-container label {
    display: flex;
    color: #FFFFFF;
    margin-bottom: 5px;
  }
  
  .input-container input {
    width: 100%;
    padding: 8px;
    font-size: 1em;
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    transition: border-color 0.3s;
    color: #FFFFFF;
  }

  .input-container input:focus {
    border-color: #016A40;
    outline: none;
  }
  
  button {
    background-color: #016a40;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  button[disabled] {
    background-color: gray;
    cursor: not-allowed;
  }
  
  button:hover {
    background-color: #005934;
  }

  .error-message {
    font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
    font-weight: normal; /* Ajustez le poids de la police si nécessaire */
    transition: font-size 0.3s; /* Transition pour une animation douce */
    color: #FFFC00; /* Couleur du texte rouge */
    font-style: italic; /* Texte en italique */
  }

  .password-container {
    position: relative;
    width: 100%;
  }
  .toggle-password-visibility {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgb(105, 105, 105);
  }
  .confirm-password-container {
    position: relative;
    width: 100%;
  }
  .toggle-confirm-password-visibility {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgb(105, 105, 105);
  }

</style>
  
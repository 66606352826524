<template>
    <div v-if="isVisibleEvaluate" class="modal-backdrop-evaluate" @click.self="closeModalEvaluate">
      <div class="modal-content">
        <div class="modal-header">
          <h3><i class="fas fa-thumbs-up"></i> Évaluez ce bureau</h3>
        </div>
  
        <div class="modal-body">
          <div class="rating-container">
            <!-- Premier div avec les étoiles -->
            <div class="star-rating">
                <i 
                    v-for="(star, index) in stars" 
                    :key="index" 
                    :class="starClass(index)" 
                    @click="rate(index)"
                    class="star-icon"
                ></i>
            </div>
  
            <!-- Second div avec une zone de texte -->
            <div class="comment-section">
              <textarea v-model="commentEvaluate" placeholder="Laissez un commentaire ici..."></textarea>
            </div>
          </div>
        </div>
  
        <div class="modal-buttons twice-group">
          <button @click="confirmEvaluate" class="apply-btn"  :disabled="loading">
            <span v-if="loading">
                Traitement en cours... <i class="fas fa-spinner fa-spin"></i>
            </span>
            <span v-else>
                <i class="fas fa-check-circle"></i> Confirmer
            </span>
            
          </button>

          <button @click="closeModalEvaluate" class="cancel-btn">
                <i class="fa fa-times"></i> Annuler
          </button>
        </div>
      </div>
    </div>
</template>

<script>

    import axios from 'axios';
    import { SERVER_IP } from '../../config';
    import { checkAuth } from '@/utils/validationUtils';

export default {

  name: 'ModalEvaluate',
  props: {
    isVisibleEvaluate: {
      type: Boolean,
      required: true,
    },
    officeId: {
      type: Number,
      required: true,
    },
  },


  data() {
    return {
      selectedRating: 0, // Variable qui garde le nombre d'étoiles sélectionnées
      stars: [1, 2, 3, 4, 5], // Nombre d'étoiles
      loading: false,
      officeIDEvaluate: this.officeId,
      commentEvaluate: '',
      averageRating: 0,
    };
  },

  watch: {
    officeId(newVal) {
      this.officeIDEvaluate = newVal; // Met à jour si la prop change
    },
  },

  methods: {
    closeModalEvaluate() {
      this.$emit('close');
    },
    async confirmEvaluate(){
        
        this.loading = true;
        const userId = checkAuth();
        const url = `${SERVER_IP}/daily-exchanges/setRatingsOffice`;
        
        try {
            const response = await axios.post(url, { 
              userId: userId,
              rating: this.selectedRating,
              comment: this.commentEvaluate,
              officeID: this.officeIDEvaluate
          });

          if (response.status === 200){
            this.averageRating = response.data.averageRating;
            this.$emit('average-rating-updated', this.averageRating);
            this.loading = false;
          }

            
        } catch (error) {
            this.loading = false;
        } finally {
            this.closeModalEvaluate()
        }
    },
    // Méthode pour gérer le clic sur une étoile
    rate(index) {
      this.selectedRating = index + 1; // Met à jour le nombre de points sélectionnés
    },
    // Méthode pour déterminer la classe des étoiles
    starClass(index) {
      return index < this.selectedRating ? 'fas fa-star' : 'far fa-star';
    }
    
  },

  
};
</script>


<style scoped>
.modal-backdrop-evaluate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Superposition */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  font-size: 0.8rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  color: #000;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  color: #000;
  text-align: center; 
  width: 300px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.modal-buttons button {
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #016A40;
  color: white;
  cursor: pointer;
}

.modal-buttons button:last-child {
  background-color: #dc3545;
}

.twice-group {
  display: flex;
  justify-content: space-between; /* Espace les boutons */
  width: 100%;
}


.star-rating {
  display: flex;
  justify-content: center;
  gap: 5px; /* Espacement entre les étoiles */
  margin-bottom: 20px;
}

.star-rating i {
  font-size: 30px;
  color: #f39c12; /* Couleur des étoiles */
  cursor: pointer;
}

.comment-section textarea {
  width: 100%;
  max-width: 400px;
  height: 50px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.comment-section textarea:focus  {
  border-color: #016A40;
  outline: none;
}

textarea::placeholder {
  color: #999;
}


</style>
<!-- App.vue -->

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { checkUpdateToken } from '@/utils/utilsToken';

export default {
  name: 'App',

  mounted() {
    this.tokenInterval =  setInterval(() => {
      checkUpdateToken();
    }, 15000);
  },
};
</script>

<style scoped>
  /*
  body{
    /*background-color: #000103;
  }*/
</style>
<template>
    <Header />
    <PageHeader title="Newsletter" />
    <div class="newsltter-page">

        <img src="@/assets/images/ewallet_26.png" alt="Mockup" class="mockup-image">

        <div>
          <div v-if="nosuccess" class="form-container">
              <form @submit.prevent="handleSubmit" novalidate>
              <div class="form-group">
                  <label for="email"><i class="fas fa-envelope icon-bg"></i> Email:</label>
                  <input type="email" id="email" v-model="form.email" @input="handleEmailInput"  required>
                  <span v-if="emailError" class="error-message">{{ emailError }}</span>
              </div>

              <div class="form-group">
                  <select id="action" v-model="form.action" required>
                  <option value="subscribe">S'abonner</option>
                  <option value="unsubscribe">Se désabonner</option>
                  </select>
              </div>

              <button type="submit" :disabled="loading">
                  <span v-if="loading">
                      Envoie en cours... <i class="fas fa-spinner fa-spin"></i>
                  </span>
                  <span v-else>
                      <i class="fas fa-arrow-right"></i> Soumettre
                  </span>
              </button>

              <div class="form-group">
                  <span v-if="requestError" class="error-message error-submit">{{ requestError }}</span>
              </div>

              </form>
          </div>
          
          <div v-else>
            <p>{{ successMessage }}</p>
              <button @click="goToHome" class="btn-home">
                <i class="fas fa-home"></i> Page d'accueil 
              </button>
          </div>

        </div>
        

    </div>

    <Footer :key="footerKey" />

</template>

<script>
  //import axios from 'axios';
  import Header from './shared/HeaderPage.vue';
  import Footer from './shared/FooterPage.vue';
  import PageHeader from './shared/PageHeader.vue';

  import axios from 'axios';
  import { SERVER_IP } from '../config';
  import { validateEmail } from '@/utils/validationUtils';

  import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

  export default {
    name: 'NewsLetter',
    mixins: [refreshFooterMixin],
    components: {
      Header,
      PageHeader,
      Footer
    },

    data() {
        return {
            form: {
                email: '',
                action: 'subscribe',
            },
            emailError: '',
            requestError: '', 
            loading: false,
            nosuccess: true,
        };
    },

    computed: {
      successMessage() {
        return this.form.action === 'subscribe' 
          ? "Inscription réussie ! Vous êtes maintenant abonné à notre newsletter. Attendez-vous à recevoir nos dernières nouvelles." 
          : "Désabonnement effectué avec succès. Vous ne recevrez plus nos newsletters. Au revoir et merci.";
      }
    },
    
    methods: {
        
        handleEmailInput() {
            this.emailError = ""; // Masquez le message d'erreur
            this.requestError = "";
        },

        async handleSubmit() {
          this.loading = true; // Début du chargement

          let validationResult = validateEmail(this.form.email);
          if(!validationResult.isValid){
              this.emailError = validationResult.errorMessage;
              this.loading = false;
              return;
          }

          const url = `${SERVER_IP}/newsletter/request-newsletter`;
          try {
              const response = await axios.post(url, { 
                  email: this.form.email,
                  action: this.form.action,
              });
              if (response.data.success){
                  this.nosuccess = false;
              }
          } catch (error) {
              this.loading = false;
              this.requestError = "Une erreur est survenue lors de la soumission.";
          } finally {
              this.loading = false;
          }
          
        },

        goToHome() {
          this.$router.push('/home');
        }

    }

  }

</script>

<style scoped>

.newsltter-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: -20px;
  color: #FFFFFF;
  margin-bottom:80px;
}

.mockup-image {
  width: 150px;
  height: auto;
  margin: 100px 0;
}

.form-container {
  padding: 10px 0px;
  /*background-color: #f8f8f8; /* Couleur de fond pour le formulaire */
  border-radius: 8px; /* Coins arrondis */
  max-width: 400px; /* Largeur maximale pour le formulaire */
  margin: 0 auto; /* Centrer le formulaire horizontalement */
}

.form-group {
  margin-bottom: 15px; /* Espacement entre les champs du formulaire */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input,
.form-group select{
  padding: 0.5em 0;
  font-size: 1em;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}
.form-group input:focus,
.form-group select:focus{
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}

button {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 10px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  margin-top: 25px;
}

button:hover {
  background-color: #005934; /* Changement de couleur au survol */
}
button:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

.form-group select option{
  color: #000;
}


.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

.form-group .error-message {
  display: block;
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
  text-align: left;
}

.form-group .error-submit{
  text-align: center;
}


</style>
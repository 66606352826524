<template>
    <div v-if="isVisibleAskLogin" class="modal-backdrop-asklogin"  @click.self="closeModalAskLogin">
      <div class="modal-content">
        <div class="modal-header">
          <h3><i class="fas fa-lock"></i> Connection requise</h3>
        </div>
  
        <div class="modal-body">
            Connexion requise pour accéder à cette option
        </div>
  
        <div class="modal-buttons twice-group">
          <button @click="gotoLogin" class="apply-btn"><i class="fas fa-user"></i> Connecter</button>
          <button @click="closeModalAskLogin" class="cancel-btn"><i class="fa fa-times"></i> Fermer</button>
        </div>
      </div>
    </div>
</template>


<script>
export default {
  name: 'ModalAskLogin',
  props: {
    isVisibleAskLogin: {
      type: Boolean,
      required: true
    },
  },

  methods: {
    closeModalAskLogin() {
      this.$emit('close');
    },
    gotoLogin() {
      this.$router.push('/'); // Redirection après déconnexion
    },
    
  },

  
};
</script>

<style scoped>
  .modal-backdrop-asklogin {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ajout du z-index pour superposition */

  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 8px;
    width: 300px;
    font-size: 0.8rem;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    color: #000;
  }
  
  .modal-body {
    display: flex;
    flex-direction: column;
    color: #000;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #016A40;
    color: white;
    cursor: pointer;
  }
  
  .modal-buttons button:last-child {
    background-color: #dc3545;
  }

  .twice-group {
    display: flex;
    justify-content: space-between; /* Pour espacer les éléments */
    width: 100%; 
  }

</style>
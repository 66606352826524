export const departments = [
    { id: 1, name: 'Artibonite' },
    { id: 2, name: 'Centre' },
    { id: 3, name: 'Grand\'Anse' },
    { id: 4, name: 'Nippes' },
    { id: 5, name: 'Nord' },
    { id: 6, name: 'Nord-Est' },
    { id: 7, name: 'Nord-Ouest' },
    { id: 8, name: 'Ouest' },
    { id: 9, name: 'Sud' },
    { id: 10, name: 'Sud-Est' }
  ];

  export const citiesByDepartment =  {
    1: [
      'Gonaïves', 'Saint Marc', 'Petite-Rivière-de-l\'Artibonite', 'Gros-Morne',
      'Saint-Michel-de-l\'Attalaye', 'Desdunes', 'Dessalines', 'L’Estère', 'Verrettes'
    ],
    2: [
      'Hinche', 'Mirebalais', 'Thomassique', 'Maïssade', 'Thomonde', 'Lascahobas', 'Belladère'
    ],
    3: [
      'Jérémie', 'Anse-d\'Ainault'
    ],
    4: [
      'Miragoâne'
    ],
    5: [
      'Cap-Haïtien', 'Limbé', 'Limonade', 'Plaisance', 'Grande Rivière du Nord',
      'Saint-Raphaël', 'Pignon', 'Terrier-Rouge', 'Port-Margot', 'Dondon', 'Plaine-du-Nord',
      'Dame-Marie', 'Acul-du-Nord'
    ],
    6: [
      'Ouanaminthe', 'Trou-du-Nord', 'Fort-Liberté'
    ],
    7: [
      'Port-de-Paix', 'Saint-Louis-du-Nord', 'Jean Rabel'
    ],
    8: [
      'Port-au-Prince', 'Carrefour', 'Delmas', 'Pétionville', 'Cité Soleil',
      'Croix-des-Bouquets', 'Petit-Goâve', 'Léogâne', 'Tabarre', 'Cabaret', 'Anse-à-Galets',
      'Grand-Goâve', 'Arcahaie', 'Kenscoff', 'Gressier', 'Fonds-Verrettes', 'Thomazeau', 'Ganthier'
    ],
    9: [
      'Les Cayes', 'Aquin'
    ],
    10: [
      'Jacmel'
    ]
  };
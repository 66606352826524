<template>
  <Header />
  <PageHeader :title="$t('pageBasicTransfert.pageTitle')" :goBack="customGoBack" />

  <div class="basic-transfert" v-if="activeIndex === 0">

    <!-- Mockup image -->
    <img src="@/assets/images/ewallet_16.png" alt="Mockup d'interface de connexion" class="mockup-image">

    <form @submit.prevent="handleSubmit" novalidate>
      <div class="form-group">
        <label for="contact">
          <i class="fas fa-user icon-bg"></i>
          {{ $t('pageBasicTransfert.phoneOrEmail2') }}
        </label>
        <input
          type="text"
          id="contact"
          v-model="form.contact"
          @input="handleContactInput"
          :placeholder="$t('pageBasicTransfert.phoneemail3')"
          required
        />
        <span class="error-message" v-if="contactError">{{ contactError }}</span>
      </div>

      <div class="form-group">
        <label for="currency">
          <i class="fas fa-coins icon-bg"></i>
          {{ $t('pageBasicTransfert.devise') }}
        </label>
        <select
          id="currency"
          v-model="form.selectedCurrency"
          required
        >
          <option value="HTG">Gourde (HTG)</option>
          <option value="USD">Dollar (USD)</option>
        </select>
      </div>

      <div class="form-group">
        <label for="amount">
          <i class="fas fa-money-bill icon-bg"></i>
          {{ $t('pageBasicTransfert.Mount') }}
        </label>
        <input
          type="number"
          id="amount"
          v-model.number="form.amount"
          @input="handleAmountInput"
          :placeholder="$t('pageBasicTransfert.AmountInput')"
          required
        />
        <span class="error-message" v-if="amountError">{{ amountError }}</span>
      </div>

      <button type="submit" class="btn-submit" :disabled="loading">
        <span v-if="loading">
          {{ $t('pageBasicTransfert.subboutton') }} <i class="fas fa-spinner fa-spin"></i>
        </span>
        <span v-else>
          {{ $t('pageBasicTransfert.Boutton') }} <i class="fas fa-paper-plane"></i>
        </span>
      </button>

    <div>
      <span class="error-message validate-error" v-if="validateError">{{ validateError }}</span>
    </div>

    </form>
  </div>

  <div class="block-a2f" v-if="activeIndex === 1">
    <img src="@/assets/images/ewallet_40.png" alt="confirmation enregistrement" class="mockup-image" />
    <p v-html="$t('pageBasicTransfert.no_pin')"></p>
    <button @click="goTo2AF" class="btn-submit">
      {{ $t('pageBasicTransfert.create_pin') }} <i class="fas fa-user-lock"></i>
    </button>
  </div>


  <div class="invoice-block"  v-if="activeIndex === 2">
    
    <img src="@/assets/images/ewallet_16.png" alt="Mockup d'interface de connexion" class="mockup-image">
    
    <!-- Détails du contact -->
    <div class="invoice-detail">
      <label>{{ $t('pageBasicTransfert.name') }} :</label>
      <span>{{ invoice.fullname || 'Nom indisponible' }} ({{ invoice.phone || 'No indisponible' }})</span>
    </div>

    <!-- Détails du montant -->
    <div class="invoice-detail">
      <label>{{ $t('pageBasicTransfert.Mount') }} :</label>
      <span>
        {{ invoice.currency === 'HTG' ? formatCurrency(invoice.amount, 'HTG', 'fr-HT') : formatCurrency(invoice.amount, 'USD', 'en-US') }}
      </span>
    </div>

    <!-- Détails de la devise -->
    <div class="invoice-detail">
      <label>{{ $t('pageBasicTransfert.deviseT') }} :</label>
      <span>{{ invoice.currency }}</span>
    </div>

    <!-- Détails des frais -->
    <div class="invoice-detail">
      <label>{{ $t('pageBasicTransfert.fees') }}  :</label>
      <span>{{ invoice.currency === 'HTG' ? formatCurrency(invoice.fee, 'HTG', 'fr-HT') : formatCurrency(invoice.fee, 'USD', 'en-US') }}</span>
    </div>

    <!-- Détails du montant total -->
    <div class="invoice-detail total">
      <label>{{ $t('pageBasicTransfert.mountT') }} :</label>
      <span>{{ invoice.currency === 'HTG' ? formatCurrency(invoice.amount + invoice.fee, 'HTG', 'fr-HT') : formatCurrency(invoice.amount + invoice.fee, 'USD', 'en-US') }}</span>
    </div>
    
    <h4 class="title-pin">{{ $t('pageBasicTransfert.pin') }}</h4>

    <div class="pin-container">
        <div class="pin-inputs">
            <!-- Les inputs avec ref dynamique -->
            <input v-for="(digit, index) in pin" 
                :key="index" 
                v-model="pin[index]" 
                maxlength="1"
                :ref="'pin' + index" 
                @input="onInput(index)" 
                @keydown.backspace="onBackspace(index)" 
                :type="isRevealed ? 'text' : 'password'" 
                class="pin-box" 
                inputmode="numeric" 
                pattern="[0-9]*"
                @keypress="isNumber($event)" />
            
            <!-- Icône pour révéler le code PIN -->
            <i class="fas fa-eye eye-icon" @mousedown="revealPin" @mouseup="hidePin" @mouseleave="hidePin"></i>
        </div>
    </div>

    <!-- Bouton de validation avec une icône FontAwesome -->
    <button type="submit" class="btn-submit" :disabled="loadingPin" @click="concluate">
      <span v-if="loadingPin">
        {{ $t('pageBasicTransfert.subboutton2') }} <i class="fas fa-spinner fa-spin"></i>
      </span>
      <span v-else>
        {{ $t('pageBasicTransfert.Boutton2') }} <i class="fas fa-check-circle"></i>
      </span>
    </button>


    <div>
      <span class="error-message validate-error" v-if="pinError">{{ pinError }}</span>
    </div>

  </div>

  <div class="block-a2f" v-if="activeIndex === 3">
    <img src="@/assets/images/confirmed-register.png" alt="confirmation enregistrement" class="mockup-image" />
    <p v-html="$t('pageBasicTransfert.success')"></p>
    <button @click="goToHome" class="btn-submit">
        {{ $t('pageBasicTransfert.Boutton3') }} <i class="fas fa-home"></i>
    </button>
  </div>


  <Footer :key="footerKey" />

</template>

<script>

import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';

import axios from 'axios';
import { SERVER_IP } from '../../config';

import { formatCurrency, validateContact, checkAuth } from '@/utils/validationUtils';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

export default {
  name: 'BasicTransfer',
  mixins: [refreshFooterMixin],

  components: {
      Header,
      PageHeader,
      Footer
  },
  created(){
    checkAuth();
  },

  data() {
    return {
      form: {
        contact: '',
        selectedCurrency: 'HTG',
        amount: 0,
      },
      invoice: {
        fullname: '',
        phone: '',
        currency: '',
        amount: 0,
        fee: 0
      },
      pin: ["", "", "", ""], // Tableau pour stocker les chiffres du PIN
      loading: false,
      loadingPin: false,
      contactError: '',
      amountError: '',
      validateError: '',
      pinError: '',
      activeIndex: 0,
    };
  },
  methods:{

    formatCurrency(amount, currency, locale) {
      return formatCurrency(amount, currency, locale);
    },
    goToHome() {
      this.$router.push('/home');
    },
    goTo2AF(){
      this.$router.push('/create-pin');
    },
    customGoBack() {
      (this.activeIndex === 0 || this.activeIndex === 4) ? this.$router.go(-1) : this.activeIndex = 0;
    },

    handleContactInput() {
      this.contactError = ''; // Réinitialiser le message d'erreur
      this.amountError = '';
      this.validateError = '';
      this.pinError = '';
    },
    handleAmountInput() {
      this.contactError = ''; // Réinitialiser le message d'erreur
      this.amountError = '';
      this.validateError = '';
      this.pinError = '';
    },

    // Empêche l'utilisateur d'entrer des lettres
    isNumber(event) {
        const charCode = event.keyCode ? event.keyCode : event.which;
        if (charCode < 48 || charCode > 57) { // 48-57 correspond aux chiffres 0-9
            event.preventDefault();
        }
    },

    // Lorsque l'utilisateur entre un chiffre dans un input
    onInput(index) {
        if (this.pin[index] && index < this.pin.length - 1) {
            // Si le champ est rempli, passer automatiquement au champ suivant
            this.$nextTick(() => {
                const nextInput = this.$refs['pin' + (index + 1)][0];
                if (nextInput) nextInput.focus();
            });
        }
        this.pinError = '';
    },

    // Gestion de la suppression d'un chiffre
    onBackspace(index) {
        if (!this.pin[index] && index > 0) {
            // Si le champ est vide après suppression, revenir au champ précédent
            this.$nextTick(() => {
                const prevInput = this.$refs['pin' + (index - 1)][0];
                if (prevInput) prevInput.focus();
            });
        } 
        this.pinError = '';
    },

    // Révéler le PIN pendant 1 seconde
    revealPin() {
        this.isRevealed = true;
        setTimeout(() => {
            this.isRevealed = false;
        }, 3000); // Masque le code après 3 secondes
    },

    hidePin() {
        this.isRevealed = false;
    },

    // Validation du PIN
    validatePin() {
        if (this.pin.join('').length === 4) {
            return true;
        } else {
            return false;
        }
    },

    getErrorMessage(errorCode) {
      switch (errorCode) {
          case 'missing_data':
              return this.$t('pageBasicTransfert.infoMissed');
          case 'no_account':
              return this.$t('pageBasicTransfert.wrongaccount');
          case 'inactive_account':
              return this.$t('pageBasicTransfert.inactifAccount');
          case 'same_account':
              return this.$t('pageBasicTransfert.sameAccount');
          case 'insufficient_funds':
              return this.$t('pageBasicTransfert.Insufficient_funds');
          default:
              return this.$t('pageBasicTransfert.unknownError');
      }
    },

    scrollToError(field) {
        const element = document.getElementById(field);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        this.loading = false;
    },

    async handleSubmit() {
      this.loading = true; // Début du chargement

      let validationResult = validateContact(this.form.contact);
      if(!validationResult.isValid){
          this.contactError = validationResult.errorMessage;
          this.scrollToError('contactError');
          return;
      }
      
      if(this.form.amount <= 0) {
          this.amountError = this.$t('pageBasicTransfert.withoutmount');
          this.scrollToError('amountError');
          return;
      }

      const url = `${SERVER_IP}/basic-transfer/validate`;
      try {
          const userId = checkAuth();
          const response = await axios.post(url, { 
              contact: this.form.contact,
              currency: this.form.selectedCurrency,
              amount: this.form.amount,
              userId: userId
          });

          // Vérifiez la réponse de l'API
          if (response.status === 200) {
              const responseData = response.data;
              
              if (responseData.message === 'validate') {
                  // L'API a validé avec succès le transfert
                  this.createInvoice();
              }
              if(responseData.message === 'no_pin'){
                this.activeIndex = 1;
              }
          } else {
              // Gérer le cas où la réponse n'est pas 200 OK
              this.validateError = this.$t('pageBasicTransfert.unknownError');
              this.scrollToError('validateError');
          }
      } catch (error) {

        if (error.response){
          this.validateError = this.getErrorMessage(error.response.data.error);
          this.scrollToError('validateError');
        }

      } finally {
          this.loading = false; // Assurez-vous de désactiver le chargement dans tous les cas
      }
    },

    async createInvoice(){
      this.loading = true; // Début du chargement
      const url = `${SERVER_IP}/basic-transfer/invoice`;
      try {
        const response = await axios.post(url, { 
            contact: this.form.contact,
            currency: this.form.selectedCurrency,
            amount: this.form.amount
        });
        if (response.status === 200) {
          this.invoice = response.data;
          this.activeIndex = 2;
        }
        
      } catch (error) {
        if (error.response){
          this.validateError = this.getErrorMessage("");
          this.scrollToError('validateError');
        }
      } finally {
          this.loading = false; // Assurez-vous de désactiver le chargement dans tous les cas
      }
      
    },

    async concluate(){
      this.loadingPin = true;
      if(!this.validatePin()){
        this.pinError = this.$t('pageBasicTransfert.pinALpha'); 
        this.loadingPin = false;
        return;
      }

      const url = `${SERVER_IP}/basic-transfer/execute`;
      try {
          const userId = checkAuth();
          const response = await axios.post(url, { 
              contact: this.form.contact,
              currency: this.form.selectedCurrency,
              amount: this.form.amount,
              fee: this.invoice.fee,
              pin: this.pin.join(''),
              userId: userId
          });
          if (response.status === 200) {
            this.activeIndex = 3;
          }
        
      } catch (error) {
        if (error.response && error.response.data.error === 'invalid_pin'){
          this.pinError = this.$t('pageBasicTransfert.errorpin');
          this.scrollToError('pinError');
        }
        else{
          this.pinError = this.getErrorMessage("");
          this.loadingPin = false;
        }
        
      }
      finally {
          this.loadingPin = false; // Assurez-vous de désactiver le chargement dans tous les cas
      }
    }

  }

  
}

</script>

<style scoped>
.block-a2f{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
}
.block-a2f p{
  font-size: 1rem;
}

.basic-transfert {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
}

.mockup-image {
max-width: 120px;
margin: 20px auto;
display: block;
}


.form-group {
margin-bottom: 15px;
text-align: left;
}

.form-group i{
margin-right: 10px;
}

.form-group label {
display: flex;
align-items: center;
margin-bottom: 5px;
}

.form-group input, .form-group select {
width: 95%;
padding: 8px;
font-size: 1em;
border: none;
border-bottom: 2px solid white;
background-color: transparent;
transition: border-color 0.3s;
color: #FFFFFF;
}

.form-group input:focus, .form-group select:focus{
border-bottom: 2px solid #016A40; /* Couleur de focus verte */
outline: none;
}
.form-group select option{
color: #000;
}

.btn-submit {
background-color: #016a40;
color: white;
border: none;
padding: 10px 20px;
font-size: 1em;
cursor: pointer;
border-radius: 5px;
transition: background-color 0.3s;
margin-top: 15px;
}

.btn-submit:hover {
background-color: #005934;
}

.icon-bg {
background-color: #016A40;
color: white;
padding: 5px; /* Taille des icônes réduite */
border-radius: 8px; /* Coins arrondis */
margin-right: 10px;
font-size: 0.6em; /* Réduire la taille des icônes */
width: 15px;
height: 15px;
text-align: center;
font-size: 0.9em;
}

.error-message {
font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
font-weight: normal; /* Ajustez le poids de la police si nécessaire */
transition: font-size 0.3s; /* Transition pour une animation douce */
color: #FFFC00; /* Couleur du texte rouge */
font-style: italic; /* Texte en italique */
margin-top: 5px;
}

.invoice-block{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5.5%;
}

.invoice-detail {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.invoice-detail.total {
font-weight: bold;
border-top: 1px solid #ddd;
padding-top: 20px;
}

.validate-button {
background-color: #28a745;
color: white;
padding: 10px 20px;
border: none;
border-radius: 5px;
cursor: pointer;
display: flex;
align-items: center;
}

.validate-button i {
margin-right: 8px;
}

.title-pin{
font-weight: normal;
font-size: 1.5rem;
}

.pin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pin-label {
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.pin-inputs {
  display: flex;
  gap: 10px;
  position: relative;
}

.pin-box {
  width: 50px;
  height: 50px;
  border: 2px solid #000103;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.eye-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>

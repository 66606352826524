<template>
    <div v-if="isVisibleModalLogOut" class="modal-logout">
        <div class="modal-logout-content">
            <h3>Voulez-vous vraiment vous déconnecter ?</h3>
            <div class="button-container">
              <button class="btn-cancel" @click="cancelLogout">Non</button>
              <button class="btn-confirm" @click="confirmLogout">Oui</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ModalLogOutComponent', // Nom du composant

  props: {
    showModalLogOut: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisibleModalLogOut: this.showModalLogOut,
    };
  },

  watch: {
    showModalLogOut(newVal) {
      this.isVisibleModalLogOut = newVal;
    },
  },

  methods: {
    cancelLogout() {
      this.isVisibleModalLogOut = false;
      this.$emit('closeModal'); // Informer le parent que le modal doit être fermé
    },
    confirmLogout() {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
      if (localStorage.getItem('tokenUser')) {
        localStorage.removeItem('tokenUser');
      }
      this.$emit('closeModal'); // Notifier le parent de la fermeture
      this.$router.push('/'); // Redirection après déconnexion
    },
  },
};
</script>

<style scoped>
/* Modal styles */
.modal-logout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-logout-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: space-around; /* ou space-between selon le rendu que vous préférez */
  width: 100%; /* ou toute autre largeur que vous voulez */
  padding: 10px;
}

button {
  padding: 10px 20px; /* Ajustez la taille des boutons si nécessaire */

  border: none;
  color: white;
  cursor: pointer;
}

.btn-confirm {
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.btn-confirm:hover {
  background-color: #005934;
}


.btn-cancel {
  background-color: #adb5bd;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.btn-cancel:hover {
  background-color: #6a6b6d;
}
</style>

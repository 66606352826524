<template>
    <div class="modal-overlay" v-if="isOpen">
      <div class="modal-content">
        <h3>Termes et Conditions de l'Épargne à Terme</h3>

        <div class="content-text">
            <p>Merci d’avoir choisi notre service d’épargne à terme. En utilisant ce service, vous acceptez les termes et conditions suivants :</p>

            <h4>1. Durée de l'Épargne :</h4>
            L'épargne à terme est bloquée pour une durée fixe, telle que sélectionnée au moment de la création de l'épargne. Pendant cette période, vous ne pourrez pas accéder aux fonds déposés.

            <h4>2. Taux d’Intérêt :</h4>
            Le taux d’intérêt applicable est déterminé en fonction de la durée de l’épargne, du montant déposé et de la devise choisie. Ce taux est fixe pendant toute la durée de l’épargne.

            <h4>3. Paiement des Intérêts :</h4>
            Les intérêts accumulés seront ajoutés au montant initial à la fin de la période d'épargne. Vous recevrez le montant total (capital + intérêts) sur votre compte principal à la date de maturité.

            <h4>4. Remboursement à Échéance :</h4>
            À la fin de la période d’épargne, le montant total (y compris les intérêts) sera automatiquement transféré sur votre compte principal.

            <h4>5. Retrait Anticipé :</h4>
            Aucun retrait anticipé n'est autorisé pendant la durée de l'épargne à terme. Une fois que vous avez placé une épargne à terme, les fonds sont bloqués jusqu'à l’échéance.

            <h4>6. Frais de Service :</h4>
            Aucun frais de gestion n'est appliqué pour la création ou la gestion de votre épargne à terme. Cependant, des frais peuvent s'appliquer pour des services supplémentaires.

            <h4>7. Confidentialité et Sécurité :</h4>
            Votre épargne est sécurisée et protégée. Nous vous demandons de ne partager votre code PIN avec personne. Vous êtes responsable de la confidentialité de vos informations de connexion.

            <h4>8. Modifications des Conditions :</h4>
            Nous nous réservons le droit de modifier les termes et conditions à tout moment. Vous serez informé de tout changement avant leur entrée en vigueur.

            <p>En cliquant sur <b>Continuer</b>, vous confirmez avoir lu et accepté ces termes et conditions, et vous vous engagez à respecter les conditions définies pour l'épargne à terme.</p>
        </div>
  
        <!-- Boutons de validation -->
        <div class="modal-buttons">
            <button @click="closeModal"><i class="fas fa-times"></i> Annuler</button>
            <button @click="applyFilters"><i class="fas fa-thumbs-up"></i> Continuer</button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'ModalConditions',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },
        applyFilters() {
            this.$emit('average-confirm', true);
        },
    },

}

</script>


<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #000;
  }

  .modal-content::-webkit-scrollbar {
    width: 0px; /* Cache la barre de défilement dans Chrome, Safari */
    background: transparent; /* Optionnel : pour enlever un arrière-plan */
  }
  .content-text{
    text-align: left;
    text-align: justify;
    font-size: 0.8rem;
    max-height: 65vh; /* Limite la hauteur de la modale pour éviter qu'elle dépasse l'écran */
    overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
    scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
  }
  
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #dc3545;
    color: white;
    cursor: pointer;
  }
  
  .modal-buttons button:last-child {
    background-color: #016A40;
  }





</style>
<template>
    <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
      <div class="modal-content">
        <h3>Filtres de transactions</h3>

        <!-- Section de Date -->
        <div class="filter-section">
            <h4><i class="fas fa-calendar"></i> Choix de date</h4>
            <div class="checkbox-group">
                <label class="select-all">
                    <input type="checkbox" v-model="isRange" @change="selectIsRange" /> Sélectionner une plage de dates
                </label>
            </div>
            

            <!-- Input de date -->
            <div class="input-date single-date" v-if="!isRange">
                <label for="singleDate">Date :</label>
                <input type="date" v-model="filters.singleDate" id="singleDate" />
            </div>

            <!-- Inputs pour la plage de dates -->
            <div class="input-date plage-date" v-else>
          
                <label for="startDate">De :</label>
                <input 
                  type="date" 
                  v-model="filters.startDate" 
                  id="startDate" 
                  :max="today" 
                  @change="updateEndDateLimit"
                />

                <label for="endDate">A :</label>
                <input 
                  type="date" 
                  v-model="filters.endDate" 
                  id="endDate" 
                  :min="filters.startDate" 
                  :max="today"
                />
            </div>
        </div>
  
        <!-- Section Type de transaction -->
        <div class="filter-section">
            <h4><i class="fas fa-exchange-alt"></i> Types de transaction</h4>
            <div class="checkbox-group">
                <label class="select-all">
                    <input type="checkbox" v-model="filters.allTypes" @change="selectAllTypes"> Toutes les transactions
                </label>
                <label class="sub-select" v-for="type in transactionTypes" :key="type">
                    <input 
                        type="checkbox" 
                        v-model="filters.selectedTypes" 
                        :value="type" 
                        @change="selectType(type)"> {{ type }}
                </label>
            </div>
        </div>

  
        <!-- Section Statut de transaction -->
        <div class="filter-section">
          <h4><i class="fas fa-info-circle"></i> Statut de transaction</h4>
          <div class="checkbox-group">
            <label class="select-all">
              <input type="checkbox" v-model="filters.allStatuses" @change="selectAllStatuses"> Tous les statuts
            </label>
            <label class="sub-select" v-for="status in transactionStatuses" :key="status">
              <input 
                type="checkbox" 
                v-model="filters.selectedStatuses" 
                :value="status" 
                @change="selectStatus(status)"> {{ status }}
            </label>
          </div>
        </div>
  
        <!-- Section Devise -->
        <div class="filter-section">
          <h4><i class="fas fa-wallet"></i> Devise</h4>
          <div class="checkbox-group">
            <label class="select-all">
              <input type="checkbox" v-model="filters.allCurrencies" @change="selectAllCurrencies"> Toutes les devises
            </label>
            <label class="sub-select" v-for="currency in currencies" :key="currency">
              <input 
                type="checkbox" 
                v-model="filters.selectedCurrencies" 
                :value="currency" 
                @change="selectCurrency(currency)"> {{ currency }}
            </label>
          </div>
        </div>
  
        <!-- Boutons de validation -->
        <div class="modal-buttons">
          <button @click="applyFilters">Appliquer</button>
          <button @click="closeModal">Annuler</button>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: 'ModalFiltersHistoric',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        transactionTypes: ['Dépôt', 'Transfert', 'Retrait'],
        transactionStatuses: ['Complet', 'En attente', 'Annulé'],
        currencies: ['HTG', 'USD'],
        isRange: false, // Basculer entre date unique et plage de dates
        today: '',
        filters: {
          singleDate: null,  // Pour date unique
          startDate: null,   // Pour la plage de dates (début)
          endDate: null,      // Pour la plage de dates (fin)
          allTypes: true,
          selectedTypes: [],
          allStatuses: true,
          selectedStatuses: [],
          allCurrencies: true,
          selectedCurrencies: [],
          rangeChoosen: false,
        }
      };
    },

    mounted() {
      // Vérifier si des filtres sont sauvegardés dans localStorage
      const savedFilters = localStorage.getItem('savedFilters');
      // Initialiser la date du jour au format 'YYYY-MM-DD'
      this.today = new Date().toISOString().substr(0, 10);
      
      if (savedFilters) {
        this.filters = JSON.parse(savedFilters);
      }
      else{
        // Initialiser startDate et endDate à aujourd'hui
        this.filters.startDate = this.today;
        this.filters.endDate = this.today;
      }

    },

    methods: {
       
        selectIsRange(){
          this.filters.rangeChoosen = this.isRange;
        },

        selectAllTypes() {
            if (this.filters.allTypes) {
                // Si "Toutes les transactions" est sélectionné, vide selectedTypes
                this.filters.selectedTypes = [];
            }
        },
        selectType(type) {
            // Si un type spécifique est sélectionné, désélectionner "Toutes les transactions"
            if (this.filters.selectedTypes.includes(type)) {
                this.filters.allTypes = false;
            } else {
                // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
                this.filters.allTypes = false;
            }
        },

        selectAllStatuses() {
            if (this.filters.allStatuses) {
            this.filters.selectedStatuses = [];
            }
        },

        selectStatus(status) {
            if (this.filters.selectedStatuses.includes(status)) {
                this.filters.allStatuses = false;
            } else {
                // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
                this.filters.allStatuses = false;
            }
        },
        

        selectAllCurrencies() {
            if (this.filters.allCurrencies) {
            this.filters.selectedCurrencies = [];
            }
        },

        selectCurrency(currency) {
            if (this.filters.selectedCurrencies.includes(currency)) {
                this.filters.allCurrencies = false;
            }else {
                // Si "allTypes" est déjà sélectionné, le désélectionner quand on choisit un autre type
                this.filters.allCurrencies = false;
            }
        },

        applyFilters() {
            this.$emit('apply-filters', this.filters);
            localStorage.setItem('savedFilters', JSON.stringify(this.filters));
            this.closeModal();
        },
        closeModal() {
            this.$emit('close');
        },

        updateEndDateLimit() {
          // Met à jour endDate si elle est antérieure à startDate
          if (this.filters.endDate < this.filters.startDate) {
            this.filters.endDate = this.filters.startDate;
          }
        },

    }
  };
</script>
  
<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #000;
    max-height: 70vh; /* Limite la hauteur de la modale pour éviter qu'elle dépasse l'écran */
    overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
    scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
  }

  .modal-content::-webkit-scrollbar {
    width: 0px; /* Cache la barre de défilement dans Chrome, Safari */
    background: transparent; /* Optionnel : pour enlever un arrière-plan */
  }

  
  .filter-section {
    margin-bottom: 20px;
  }
  .filter-section h4{
    color: #FFF;
    background-color: #000;
    border-radius: 15px;
    padding: 10px;
    text-align: left;
  }
  .filter-section h4 i{
    margin-right: 10px;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .checkbox-group .select-all {
    display: block;
    text-align: left;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .checkbox-group .sub-select {
    display: inline-block;
    width: calc(33.33% -10px);
    /* margin-right: 10px; */
    margin-right: 10%;
    margin-bottom: 10px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .modal-buttons button:last-child {
    background-color: #dc3545;
  }

  .input-date input {
    padding: 0.5em 0;
    font-size: 1em;
    /* width: 100%; */
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
  }

  .input-date input:focus {
    outline: none;
    border-bottom: 2px solid #016A40; /* Couleur de focus verte */
  }

  .single-date {
    display: block;
    text-align: left;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
  }
  .single-date label{
    margin-right: 10px;
  }

  .plage-date {
    display: flex; /* Utiliser Flexbox pour répartir les éléments */
    justify-content: space-between; /* Répartit les éléments uniformément */
    align-items: center; /* Aligne verticalement les éléments au centre */
    width: 100%; /* S'assure que le conteneur occupe 100% de la largeur disponible */
    gap: 10px; /* Ajoute un petit espacement entre les éléments */
    }

    .plage-date label {
    flex: 1; /* Les étiquettes prennent chacune une part égale de l'espace */
    text-align: right; /* Aligne le texte des étiquettes à droite */
    margin-right: 10px; /* Ajoute un espace à droite des étiquettes */
    }

    .plage-date input[type="date"] {
    flex: 2; /* Les champs de date prennent plus d'espace que les étiquettes */
    padding: 5px; /* Ajoute un peu de padding pour les entrées */
    width: 100%; /* S'assure que les champs d'entrée s'étendent sur toute leur zone */
    box-sizing: border-box; /* S'assure que le padding est inclus dans la largeur totale */
    }


</style>
  
// src/utils/validationUtils.js

import i18n from './translator'; // Sans accolades



export function validateFullName(fullName) {
    let textError = "";
    
    if (!fullName) {
        textError = "Veuillez entrer votre prénom et nom de famille.";
        return { isValid: false, errorMessage: textError };
    }

    const fullNameWords = fullName.split(" ");
    if (fullNameWords.length < 2) {
        textError = "Veuillez entrer au moins deux mots pour votre nom complet.";
        return { isValid: false, errorMessage: textError };
    }

    if (fullName.length < 6) {
        textError = "Votre nom complet doit comporter au moins 6 caractères.";
        return { isValid: false, errorMessage: textError };
    }

    const fullNamePattern = /^[A-Za-z\s'-]+$/;
    if (!fullNamePattern.test(fullName)) {
        textError = "Le nom complet ne doit contenir que des lettres, des espaces, des tirets et des apostrophes.";
        return { isValid: false, errorMessage: textError };
    }

    if (fullName.length > 50) {
        textError = "Votre nom complet ne doit pas dépasser 50 caractères.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };
}


export function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let textError = "";

    if (!email) {
      textError = "Veuillez entrer une adresse email.";
      return { isValid: false, errorMessage: textError };
    }

    if (!emailPattern.test(email)) {
        textError = "Veuillez entrer une adresse email valide.";
        return { isValid: false, errorMessage: textError };
    }
    
    return { isValid: true, errorMessage: "" };
}

export function validatePhone(phoneNUmber) {
    const phone = phoneNUmber.trim();
    let textError = "";
    const phonePattern = /^509(31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;
    if (!phone) {
        textError = "Le champ Numéro de téléphone est requis.";
        return { isValid: false, errorMessage: textError };
    }
    if (!phonePattern.test(phone)) {
        textError = "Le numéro de téléphone doit commencer par '509' suivi de 8 chiffres valides.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };

}

export function validateSubjectContact(subject){
    let textError = "";
    
    if (!subject) {
        textError = "Veuillez entrer un sujet.";
        return { isValid: false, errorMessage: textError };
    }

    if (subject.length > 650) {
        textError = "LE sujet ne peut dépasser 60 caractères.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };
}

export function formatDate(isoDateString) {
    // Créer un objet Date à partir de la chaîne de caractères ISO
    const date = new Date(isoDateString);

    // Options de formatage pour obtenir le jour, le mois et l'année
    const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    };

    // Utiliser l'objet Intl.DateTimeFormat pour formater la date en français
    const formatter = new Intl.DateTimeFormat('fr-FR', options);

    // Formater la date et retourner le résultat
    return formatter.format(date);
}

export function formatDateTime(isoDateString) {
    // Créer un objet Date à partir de la chaîne de caractères ISO
    const date = new Date(isoDateString);

    // Options de formatage pour obtenir le jour, le mois, l'année, l'heure et les minutes
    const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit', // Optionnel si vous souhaitez inclure les secondes
        hour12: false // Utiliser le format 24h
    };

    // Utiliser l'objet Intl.DateTimeFormat pour formater la date et l'heure en français
    const formatter = new Intl.DateTimeFormat('fr-FR', options);

    // Formater la date et retourner le résultat
    return formatter.format(date);
}

export function validatePassword(password) {
    let textError = "";

    if (!password) {
        textError = "Le champ Mot de passe est requis.";
        return { isValid: false, errorMessage: textError };
    }

    // Vérification de la longueur du mot de passe
    const hasMinimumLength = password.length >= 8;
    // Vérification des critères de sécurité
    const hasDigit = /\d/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Regroupement des vérifications
    if (!hasMinimumLength || !hasDigit || !hasLetter || !hasSpecialChar) {
        textError = "Votre mot de passe n'est pas assez fort. Il doit contenir au moins 8 caractères, incluant une majuscule, une minuscule, un chiffre et un symbole spécial (ex: !, @, #, $). Veuillez réessayer.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };
}

export function validateConfirmPassword(password, confirmPassword) {
  let textError = "";

  if (!confirmPassword) {
    textError= "Veuillez confirmer votre mot de passe.";
    return { isValid: false, errorMessage: textError };
  }
  if (confirmPassword !== password) {
    textError = "Les mots de passe ne correspondent pas.";
    return { isValid: false, errorMessage: textError };
  }
  return { isValid: true, errorMessage: "" };
}

export function validateContact(username) {
    const contact = username.trim(); // Supprimer les espaces avant et après
    let textError = "";

    // Vérifier si le champ est vide
    if (!contact) {
        textError = i18n.global.t('generalContent.validateContactError_1'); //"Le champ Numéro de téléphone ou Email est requis.";
        return { isValid: false, errorMessage: textError };
    }

    // Vérifier le format email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(contact)) {
        return { isValid: true, errorMessage: "" }; // Validation réussie pour email
    }

    // Vérifier le format numéro de téléphone
    const phoneRegex = /^509(?:31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;

    if (/^\d+$/.test(contact)) {
        if (contact.length === 8 && phoneRegex.test('509' + contact)) {
            return { isValid: true, errorMessage: "" }; // Validation réussie pour numéro à 8 chiffres
        } else if (contact.length === 11 && phoneRegex.test(contact)) {
            return { isValid: true, errorMessage: "" }; // Validation réussie pour numéro à 11 chiffres avec préfixe 509
        }
    }

    // Si aucun des formats n'est valide
    textError = i18n.global.t('generalContent.validateContactError_2');  //"Veuillez entrer un email valide ou un numéro de téléphone valide.";
    return { isValid: false, errorMessage: textError };
}

export function checkAuth() {

    const token = localStorage.getItem('tokenUser');

    // Vérifier si le token existe
    if (!token) {
        window.location.href = '/'; // Rediriger vers la page de login si aucun token n'est trouvé
        return;
    }
    try {
        // Décomposer le token pour récupérer le payload
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const decodedToken = JSON.parse(jsonPayload);

        // Vérifier si le token contient le userId et s'il est toujours valide
        if (!decodedToken.userId || (decodedToken.exp && decodedToken.exp * 1000 < Date.now())) {
            localStorage.removeItem('token');
            window.location.href = '/'; // Rediriger vers la page de login si le token est invalide ou expiré
            return;
        }

        return decodedToken.userId;
        
    } catch (error) {
        console.error('Erreur lors de la décomposition du token:', error);
        localStorage.removeItem('token');
        window.location.href = '/'; // Rediriger vers la page de login en cas d'erreur
    }
}

export function formatCurrency(amount, currency = 'USD', locale = 'en-US') {
    // Vérifier si l'amount est un nombre et le convertir si nécessaire
    if (isNaN(amount)) {
        return 'Invalid amount';
    }

    let formattedAmount = new Intl.NumberFormat(locale, {
        style: 'decimal', // Utiliser 'decimal' pour éviter d'ajouter le symbole de la devise
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);

    if (currency === 'HTG') {
        return `${formattedAmount} HTG`;
    } else if (currency === 'USD') {
        return `$${formattedAmount} USD`;
    } else {
        return `${formattedAmount} ${currency}`;
    }
}




  

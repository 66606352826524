<template>
    <Header />
    <PageHeader :title="$t('pageTitle.profile')" />


    <div class="profile-page">

        <div class="button-menu-container">
            <button v-for="(button, index) in buttons" :key="index" :class="{ active: activeIndex === index }" @click="activeIndex = index">
            <i :class="button.icon" class="button-menu-icon"></i>
            <span class="button-menu-text">{{ button.text }}</span>
            </button>
        </div>

        <div v-if="activeIndex === 0" class="block-profile">
            <div class="profile-container" v-if="user">
                <div class="profile-image">
                <img :src="getImage(user.image)" alt="User Image">
                </div>
                <div class="profile-info">
                    <div class="info-row green-bg">
                        <i class="fas fa-user"></i>
                        <span>{{ $t('message.fullName') }} : {{ user.fullName }}</span>
                    </div>
                    <div class="info-row">
                        <i class="fas fa-envelope"></i>
                        <span>{{ $t('message.email') }} : {{ user.email }}</span>
                    </div>
                    <div class="info-row green-bg">
                        <i class="fas fa-phone"></i>
                        <span>{{ $t('message.phoneNumber') }} : {{ user.phone }}</span>
                    </div>
                    <div class="info-row">
                        <i class="fas fa-map-marker-alt"></i>
                        <span>{{ $t('message.address') }} : {{ user.address }}</span>
                    </div>
                    <div class="info-row green-bg double-row">
                        <div>
                        <i class="fas fa-birthday-cake"></i>
                        <span>{{ $t('message.dateOfBirth') }} : {{ formattedBirthdate }}</span>
                        </div>
                        <div>
                        <i class="fas fa-venus-mars"></i>
                        <span>{{ $t('message.gender') }} : {{ user.gender }}</span>
                        </div>
                    </div>
                </div>

                <button>
                    <span v-if="loadingEditUser">
                        Chargement... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else @click="gotoEditUser">
                        <i class="fas fa-user-pen"></i> Modifier
                    </span>
                </button>

            </div>
            <div v-else>
                <p class="loading-page">Chargement des informations personnelles <i class="fas fa-spinner fa-spin"></i></p>
            </div>
        </div>

        <div v-if="activeIndex === 1" class="security-section">
            <br/><br/>
            <div class="button-group">
                <div class="action-button">
                <a href="/change-password">
                    <i class="fas fa-key"></i> Changer Mot de Passe
                </a>
                </div>
                <div class="action-button">
                <a href="/two-factor-auth">
                    <i class="fas fa-shield-alt"></i> Vérif. 2 Étapes
                </a>
                </div>
                <div class="action-button">
                <a href="/login-history">
                    <i class="fas fa-history"></i> Historique Connex.
                </a>
                </div>
            </div>
            <div class="button-group">
                <div class="action-button">
                <a href="/profile-visibility">
                    <i class="fas fa-eye"></i> Visibilité de Profil
                </a>
                </div>
                <div class="action-button">
                <a href="/data-management">
                    <i class="fas fa-database"></i> Gestion Données
                </a>
                </div>
                <div class="action-button">
                <a href="/privacy-notifications">
                    <i class="fas fa-lock"></i> Notifs. Confidentialité
                </a>
                </div>
            </div>
            <br/><br/>
        </div>

        <div v-if="activeIndex === 7" class="block-profile">
            <div class="profile-container" v-if="user">
                <div class="profile-image">
                <img :src="getImage(user.image)" alt="User Image">
                </div>
                <div class="profile-info">
                    <!--div class="case-info-row"><div class="icon-container">
                            <i class="fas fa-user"></i>
                        </div>
                        <div class="text-container">
                            <span class="label">Nom Complet</span>
                            <span class="value">{{ user.fullName }}</span>
                        </div>
                    </div-->

                    <div class="case-info-row">
                        <div class="icon-container">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="text-container">
                            <span class="label">Email</span>
                            <span class="value">{{ user.email }}</span>
                        </div>
                    </div>

                    <div class="case-info-row">
                        <div class="icon-container">
                            <i class="fas fa-phone"></i>
                        </div>
                        <div class="text-container">
                            <span class="label">No de Téléphone</span>
                            <span class="value">{{ user.phone }}</span>
                        </div>
                    </div>

                    <div class="case-info-row">
                        <div class="icon-container">
                            <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div class="text-container">
                            <span class="label">Adresse</span>
                            <span class="value">{{ user.address }}</span>
                        </div>
                    </div>

                    <div class="case-info-row">
                        <div class="icon-container">
                            <i class="fas fa-birthday-cake"></i>
                        </div>
                        <div class="text-container">
                            <span class="label">Date de Naissance</span>
                            <span class="value">{{ formattedBirthdate }}</span>
                        </div>
                    </div>

                    <div class="case-info-row">
                        <div class="icon-container">
                            <i class="fas fa-venus-mars"></i>
                        </div>
                        <div class="text-container">
                            <span class="label">Genre</span>
                            <span class="value">{{ user.gender }}</span>
                        </div>
                    </div>

                    <div class="case-info-row">
                        <div class="icon-container">
                            <i class="fas fa-user-check"></i>
                        </div>
                        <div class="text-container">
                            <span class="label">Statut du Compte</span>
                            <span class="value">{{ user.isActive === 1 ? 'Actif' : 'Inactif' }}</span>
                        </div>
                    </div>

                </div>

                <button>
                    <span v-if="loadingEditUser">
                        Chargement... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else @click="gotoEditUser">
                        <i class="fas fa-user-pen"></i> Modifier
                    </span>
                </button>

            </div>
            <div v-else>
                <p class="loading-page">Chargement des informations personnelles <i class="fas fa-spinner fa-spin"></i></p>
            </div>
        </div>

        <div  v-if="activeIndex === 2" class="block-preferences">
            <div class="profile-container preferences-section" v-if="preferences">
                <br/>
                <div class="info-row green-bg">
                    <i class="fas fa-globe"></i>
                    <span>Langue : {{ displayLanguage }}</span>
                </div>
                <div class="info-row">
                    <i class="fas fa-bell"></i>
                    <span>Notifications : {{displayNotifications}}</span>
                </div>
                <div class="info-row green-bg">
                    <i class="fas fa-mail-bulk"></i>
                    <span>Newsletter : {{displayNewsletter}}</span>
                </div>

                <button>
                    <span v-if="loadingEditUser">
                        Chargement... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else @click="gotoEditPreferences">
                        <i class="fas fa-wrench"></i> Modifier
                    </span>
                </button>
                <br/><br/>

            </div>
            <div v-else>
                <p class="loading-page">Chargement de vos préférences <i class="fas fa-spinner fa-spin"></i></p>
            </div>
        </div>

        <div v-if="activeIndex === 3" class="block-profile">
            <div class="profile-container profile-section" v-if="user">
                <br/>
                <div class="profile-info">
                    <div class="info-row green-bg">
                        <i class="fas fa-history"></i>
                        <span>Dernière connexion : {{ formattedLastLogin }}</span>
                    </div>
                    <div class="info-row">
                        <i class="fas fa-tachometer-alt"></i>
                        <span>Activités récentes : {{ recentActivityDescription }}</span>
                    </div>
                    <div class="info-row green-bg">
                        <i class="fas fa-bell"></i>
                        <span>Notifications : {{formattedNotifications}}</span>
                    </div>
                </div>

                <button>
                    <span v-if="loadingEditUser">
                        Chargement... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else @click="gotoHistoric">
                        <i class="fas fa-file-invoice"></i> Historique des Transactions
                    </span>
                </button>
                <br/><br/>
            </div>
            <div v-else>
                <p class="loading-page">Chargement des informations personnelles <i class="fas fa-spinner fa-spin"></i></p>
            </div>
        </div>

        <div v-if="activeIndex === 4" class="block-profile">
            <div class="profile-container account-section" v-if="user">
                <br/>
                <div class="profile-info">
                    <div class="info-row green-bg">
                        <i class="fas fa-id-badge"></i>
                        <span>Type de compte : Standard</span>
                    </div>
                    <div class="info-row">
                        <i class="fas fa-user-check"></i>
                        <span>Statut du compte : Actif</span>
                    </div>
                    <div class="info-row green-bg">
                        <i class="fas fa-link"></i>
                        <span>Services Liés : NATCASH, MONCASH</span>
                    </div>
                    <div class="info-row">
                        <i class="fas fa-mobile-alt"></i>
                        <span>Appareils Connectés : 3</span>
                    </div>
                </div>

                <button>
                    <span v-if="loadingEditUser">
                        Chargement... <i class="fas fa-spinner fa-spin"></i>
                    </span>
                    <span v-else @click="gotoEditUser">
                        <i class="fas fa-user-cog"></i> Gérer le compte
                    </span>
                </button>
                <br/><br/>
            </div>
            <div v-else>
                <p class="loading-page">Chargement des informations personnelles <i class="fas fa-spinner fa-spin"></i></p>
            </div>
        </div>

        <div v-if="activeIndex === 5" class="support-buttons support-section">
            <button @click="goToFAQ">
                <i class="fas fa-question-circle"></i>
                Consulter le Centre d'aide
            </button>
            <button @click="goToContactForm">
                <i class="fas fa-envelope"></i>
                Contacter du support via un formulaire
            </button>
            <button @click="callSupport">
                <i class="fas fa-phone"></i>
                Appeler le support : (509) 4040 9077
            </button>
            <button @click="sendEmail">
                <i class="fas fa-paper-plane"></i>
                Envoyer un email
            </button>
        </div>

        

    </div>

    <Footer :key="footerKey" />
</template>

<script>

    //import axios from 'axios';
    import Header from './shared/HeaderPage.vue';
    import Footer from './shared/FooterPage.vue';
    import PageHeader from './shared/PageHeader.vue';

    import axios from 'axios';
    import { SERVER_IP } from '../config';
    import { formatDate, checkAuth } from '@/utils/validationUtils';

    import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';


    export default {
        name: 'UserProfile',
        mixins: [refreshFooterMixin],
        components: {
            Header,
            PageHeader,
            Footer
        },
        created(){
            checkAuth();
            this.buttons = [
                { text: this.$t('message.personalInfo'), icon: 'fas fa-user' },
                { text: this.$t('message.securityPrivacy'), icon: 'fas fa-lock' },
                { text: this.$t('message.preferences'), icon: 'fas fa-cogs' },
                { text: this.$t('message.activityHistory'), icon: 'fas fa-history' },
                { text: this.$t('message.accountManagement'), icon: 'fas fa-user-cog' },
                { text: this.$t('message.supportAssistance'), icon: 'fas fa-life-ring' }
            ];
        },

        data() {
            return {
                activeIndex: 0,
                buttons: [],
                user: null,
                preferences: null,
                activities: [],
                notifications: null,
                loadingEditUser: false,
            };
        },

        mounted() {
            this.fetchUserData();
            this.fetchPreferencesData();
            this.fetchActivitiesData();
            this.fetchNotifications();
        },

        computed: {
            // Propriété calculée pour obtenir la date formatée
            formattedBirthdate() {
                return formatDate(this.user.birthdate);
            },
            displayLanguage() {
                if (!this.preferences) return '';
                const languageMap = {
                    fr: 'Français',
                    en: 'Anglais',
                    ht: 'Créole'
                };
                return languageMap[this.preferences.language] || this.preferences.language;
            },
            displayNewsletter() {
                if (!this.preferences) return '';
                return this.preferences.newsletter_subscription === 1 ? 'Abonné' : 'Désabonné';
            },
            displayNotifications() {
                if (!this.preferences) return 'Aucune notification';

                let notifications = [];
                
                if (this.preferences.email_notifications === 1) {
                    notifications.push('Email');
                }
                
                if (this.preferences.sms_notifications === 1) {
                    notifications.push('SMS');
                }
                
                if (this.preferences.app_notifications === 1) {
                    notifications.push('Web');
                }
                
                return notifications.join(', ') || 'Aucune notification';
            },
            formattedLastLogin() {
                if (this.user && this.user.last_login) {
                    const lastLogin = new Date(this.user.last_login);
                    const now = new Date();
                    const diffInMs = now - lastLogin;

                    const seconds = Math.floor(diffInMs / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    const days = Math.floor(hours / 24);

                    if (days > 0) {
                        return `Il y a ${days} jour${days > 1 ? 's' : ''}`;
                    } else if (hours > 0) {
                        return `Il y a ${hours} heure${hours > 1 ? 's' : ''}`;
                    } else if (minutes > 0) {
                        return `Il y a ${minutes} minute${minutes > 1 ? 's' : ''}`;
                    } else {
                        return `Il y a ${seconds} seconde${seconds > 1 ? 's' : ''}`;
                    }
                }
                return 'Jamais connecté';
            },
            recentActivityDescription() {
                if (this.activities.length > 0) {
                    return this.activities[0].activity_description;
                }
                    return 'Aucune activité récente';
            },
            formattedNotifications() {
                if (this.notifications === 0) {
                    return 'Aucune notification non lue';
                } else if (this.notifications === 1) {
                    return '1 notification non lue';
                } else {
                    return `${this.notifications} notifications non lues`;
                }
            },



        },

        methods: {
            goToEditUser() {
                this.$router.push('/edit-user');
            },
            getImage(image) {
                return require(`@/assets/images/${image}`);
            },
            gotoHistoric(){
                this.$router.push('/historic-transations');
            },
            gotoEditPreferences(){
                this.$router.push('/settings');
            },
            goToFAQ() {
                this.$router.push('/faq');
            },
            goToContactForm() {
                this.$router.push('/contact-page');
            },
            callSupport() {
                window.location.href = 'tel:+50940409077';
            },
            sendEmail() {
                window.location.href = 'mailto:support@kobdirek.com';
            },

            async fetchUserData() {
                try {
                    const userId = checkAuth(); 
                    const url = `${SERVER_IP}/userRequest/selectuser/${userId}`;
                    const response = await axios.get(url);
                    this.user = response.data;
                } catch (error) {
                    console.error('Erreur lors de la récupération des données utilisateur:', error);
                }
            },
            async fetchPreferencesData() {
                try {
                    const userId = checkAuth(); 
                    const url = `${SERVER_IP}/userRequest/get-preferences/${userId}`;
                    const response = await axios.get(url);
                    this.preferences = response.data;
                } catch (error) {
                    console.error('Erreur lors de la récupération des données utilisateur:', error);
                    this.preferences = {
                        language: 'fr',
                        newsletter_subscription: 1,
                        email_notifications: 1,
                        sms_notifications: 1,
                        app_notifications: 1
                    };
                }
            },
            async fetchNotifications() {
                try {
                    const userId = checkAuth(); 
                    const url = `${SERVER_IP}/userRequest/get-notifications/${userId}`;
                    const response = await axios.get(url);
                    this.notifications = response.data;
                } catch (error) {
                    console.error('Erreur lors de la récupération des données utilisateur:', error);
                }
            },
            async fetchActivitiesData() {
                const url = `${SERVER_IP}/userRequest/get-activities`;
                const userId = checkAuth();
                try {
                    
                    const response = await axios.post(url, { 
                        userId: userId,
                        limit: 1
                    });
                    
                    // Vérifiez si la réponse a réussi et contient les données attendues
                    if (response.status === 200) {
                        this.activities = response.data; // Assurez-vous que `response.data` contient les activités
                    } else {
                        console.error('Erreur: Réponse inattendue de l\'API', response.data);
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des activités:', error);
                }
            },



        }

    }
</script>

<style scoped>

.profile-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: -20px;
  color: #FFFFFF;
  margin-bottom:80px;
}

.button-menu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0px 10px 0px;
}

.button-menu-container button {
  width: calc(33.333% - 10px); /* Adjust this value to control spacing between buttons */
  padding: 20px;
  margin: 5px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-menu-container button.active {
  background-color: #016A40;
  color: white;
}

.button-menu-icon {
  font-size: 2rem;
  margin-bottom: 5px;
}

.button-menu-text {
  font-size: 0.8rem;
}


.loading-page{
    margin: 150px 0px;
}

.profile-container {
  background-color: #1A1A1D;
  border-radius: 15px;
  padding: 10px;
  max-width: 400px;
  margin: 0 auto;
  color: #fff;
}

.profile-image {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.profile-image img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.info-row {
  display: flex;
  align-items: center;
  padding: 10px;
}

.info-row i {
  margin-right: 10px;
}

.green-bg {
  background-color: #FFF;
  color: #000;
  border-radius: 5px;
}

.double-row {
  display: flex;
  justify-content: space-between;
}

.double-row div {
  display: flex;
  align-items: center;
}

.m-bold{
    font-weight: bold;
}
button {
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 30px;
}

button:hover {
  background-color: #005934;
}


/********** */

.case-info-row {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 1, 3, 0.4); /* Enhanced box-shadow */
    padding: 10px;
    margin-bottom: 10px;
}

.icon-container {
    background-color: black;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px; /* Adjust the size as needed */
    width: 50px; /* Adjust the size as needed */
    margin-right: 15px;
}

.icon-container i {
    font-size: 30px; /* Adjust the size as needed */
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
}

.text-container .label {
    font-size: 12px;
    color: gray;
    margin-bottom: 5px;
    text-align: left;
}

.text-container .value {
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: left;
}


/************************ */
.support-buttons {
  background-color: #120705;
  border-radius: 15px;
  padding: 10px;
  max-width: 400px;
  margin: 0 auto;
  color: #fff;
}

/**************** */

.security-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 400px; /* Limit the width of the entire section */
  margin: 0 auto; /* Center the section */
  padding: 10px;
  margin-top: 25px;
  margin-bottom: 10px;
  background-color: #2e2d2d;
  border-radius: 15px;
}

.button-group {
  width: 48%; /* Ensure the groups take up equal space */
}

.action-button {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 88%; /* Ensure buttons fill the group width */
}

.action-button a {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  width: 100%;
}

.action-button a i {
  margin-right: 5px;
  font-size: 1rem;
}

.action-button:hover {
  background-color: #e0e0e0;
}

.action-button:active {
  background-color: #d0d0d0;
}

.preferences-section{
    background-color: #0f1710;
    padding: 10px;
}
.profile-section{
    background-color: #10131c;
    padding: 10px;
}
.account-section{
    background-color: #120f0c;
    padding: 10px;
}

</style>

<template>
    <Header />

    <div class="terms-conditions">
      <div class="terms-content">
        <h3>
            <i class="fas fa-info-circle"></i> Conditions Générales d'Utilisation
        </h3>
        
        <img src="@/assets/images/ewallet_55.png" alt="Mockup d'interface de connexion" class="mockup-image">

        <p>Merci de lire attentivement ces conditions générales avant d'utiliser notre plateforme. En accédant à ce site web et en utilisant nos services, vous acceptez d'être lié par ces conditions. Si vous n'acceptez pas toutes les conditions énoncées sur cette page, veuillez ne pas accéder à ce site web ni utiliser nos services.</p>

        <h4 class="subtitle">Clause 1 : Objet</h4>

        <p>Les présentes conditions générales ont pour objet de définir les droits et obligations des utilisateurs et de la plateforme Kob Direk, dans le cadre de l'utilisation des services de monnaie électronique proposés.</p>

        <h4 class="subtitle">Clause 2 : Définitions</h4>

        <ul>
            <li>Utilisateur : Toute personne physique ou morale qui utilise les services proposés par la plateforme. Cela inclut toute personne qui crée un compte, accède aux fonctionnalités disponibles ou interagit avec la plateforme de quelque manière que ce soit.</li>
            <li>Plateforme : Référence à Kob Direk, exploité par Kob Direk, englobant l'ensemble des services, fonctionnalités, contenus et technologies fournis via son site web, son application mobile ou tout autre canal numérique.</li>
            <li>Services : Ensemble des prestations offertes par la plateforme, y compris la gestion de compte, les transactions financières, le commerce électronique, et autres services connexes.</li>
        </ul>

        <h4 class="subtitle">Clause 3 : Accès aux Services</h4>

        <ol>
            <li>Inscription et Informations Personnelles : L'accès à nos services de monnaie électronique nécessite une inscription préalable de l'utilisateur. L'utilisateur s'engage à fournir des informations personnelles exactes et complètes lors de son inscription.</li>
            <li>Mise à Jour des Informations : L'utilisateur est responsable de maintenir la précision de ses informations personnelles et de mettre à jour son profil en cas de changement.</li>
            <li>Authentification et Sécurité : L'utilisateur est responsable de la confidentialité de ses informations d'identification. Il s'engage à ne pas divulguer ses informations de connexion et à prendre toutes les mesures nécessaires pour protéger son compte.</li>
            <li>Restriction d'Accès : La plateforme se réserve le droit de restreindre l'accès à certains services ou fonctionnalités conformément à ses politiques de sécurité et de conformité.</li>
        </ol>
        
        <img src="@/assets/images/ewallet_17.png" alt="Mockup d'interface de connexion" class="mockup-image">


        <h4 class="subtitle">Clause 4 : Responsabilités des Utilisateurs</h4>

        <p>En utilisant nos services de monnaie électronique, l'utilisateur accepte les responsabilités suivantes :</p>

        <ol>
            <li>Conformité Réglementaire : L'utilisateur doit se conformer à toutes les lois, régulations et exigences applicables relatives à l'utilisation des services de monnaie électronique.</li>
            <li>Gestion Sécurisée du Compte : L'utilisateur est responsable de la sécurité de son compte et s'engage à prévenir tout accès non autorisé.</li>
            <li>Utilisation Appropriée : L'utilisateur s'engage à utiliser nos services uniquement à des fins légales et conformément à nos conditions générales.</li>
            <li>Protection des Données : L'utilisateur doit fournir des informations personnelles exactes et s'abstenir de divulguer ses informations d'identification à des tiers non autorisés.</li>
            <li>Utilisation des Fonds : L'utilisateur est seul responsable de l'utilisation légale des fonds chargés sur son compte de monnaie électronique.</li>
        </ol>

        <h4 class="subtitle">Clause 5 : Propriété Intellectuelle</h4>

        <p>Le contenu de la plateforme, y compris les textes, graphiques, logos, icônes, images, clips audio, clips vidéo, documents téléchargeables et tout autre contenu, est la propriété exclusive de Kob Direk ou de ses fournisseurs de contenu, protégé par les lois sur le droit d'auteur et autres lois applicables.</p>

        <h4 class="subtitle">Clause 6 : Protection des Données Personnelles</h4>

        <p>Nous nous engageons à protéger la vie privée de nos utilisateurs. Consultez notre <router-link to="/privacy-policy" class="privacy-link">politique de confidentialité</router-link> pour comprendre comment nous collectons, utilisons, protégeons et gérons vos informations personnelles.</p>

        <img src="@/assets/images/ewallet_28.png" alt="Mockup d'interface de connexion" class="mockup-image">
        
        <h4 class="subtitle">Clause 7 : Modifications des Conditions Générales</h4>

        <p>Nous nous réservons le droit de modifier ces conditions générales à tout moment. Les utilisateurs seront informés des modifications par tout moyen approprié, et l'utilisation continue de nos services après notification vaudra acceptation des nouvelles conditions.</p>

        <h4 class="subtitle">Clause 8 : Limitation de Responsabilité</h4>

        <p>Sauf en cas de faute prouvée, nous ne sommes pas responsables des dommages directs, indirects, spéciaux, consécutifs ou exemplaires résultant de l'utilisation de nos services.</p>
        
        <img src="@/assets/images/ewallet_04.png" alt="Mockup d'interface de connexion" class="mockup-image">

        <h4 class="subtitle">Clause 9 : Droit Applicable et Règlement des Litiges</h4>

        <p>Ces conditions générales sont régies par le droit en vigueur à Haïti. En cas de litige, les parties chercheront d'abord une résolution amiable. À défaut, les tribunaux compétents de Port-au-Prince, Haïti, auront la compétence exclusive pour régler le litige.</p>

        <h4 class="subtitle">Clause 10 : Contact</h4>

        <p>Pour toute question concernant ces conditions générales, contactez-nous à l'adresse suivante :</p>
        <address>
            Kob Direk<br>
            #26, Impasse Bethany, Petite Place Cazeau,<br>
            Delmas, Port-au-Prince, Ouest, Haïti.
        </address>

        <p>Vous pouvez également nous joindre par courriel à : <a href="mailto:info@kobdirek.geekzonehaiti.net">info@kobdirek.geekzonehaiti.net</a></p>

      </div>
  
      <!-- Bouton de retour -->
      <button @click="goBack" class="btn-back">
        <i class="fas fa-arrow-left"></i> Retour
      </button>

    </div>
</template>
  
<script>
  import Header from '../shared/HeaderPage.vue';

  export default {
    name: "TermsConditions",
    components: {
        Header
    },

    methods: {
      goBack() {
        this.$router.go(-1); // Retourne à la page précédente
      },
    },
  };
  </script>
  
<style scoped>
    .terms-conditions {
    max-width: 400px;
    margin: auto;
    padding: 0px 10px;
    text-align: justify;
    color: #ffffff;
    }

    .terms-content {
    margin-bottom: 80px; /* Pour que le contenu ne soit pas coupé par le bouton */
    }
    
    h3 i{
        margin-right: 10px;
    }
    h4{
        font-weight: normal;
    }

    .mockup-image {
      max-width: 200px;
      margin: 20px auto;
      display: block;
    }

    .subtitle {
        text-decoration: underline;
        text-decoration-thickness: 2px; /* Ajustez l'épaisseur du soulignement */
        text-underline-offset: 5px; /* Ajustez l'espacement entre le texte et le soulignement */
    }


    .btn-back {
    position: sticky;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #016a40;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    }

    .btn-back i {
    margin-right: 10px;
    }

    .btn-back:hover {
    background-color: #005934;
    }

    a, .privacy-link {
    color: #178ff5;
    text-decoration: none;
    }

    a:hover, .privacy-link:hover {
    color: #106bb4;
    }
</style>
  
<template>
  <div v-if="isVisible" class="modal-backdrop"  @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Filtres des Taux de Change</h3>
        <button @click="closeModal" class="close-btn">&times;</button>
      </div>

      <div class="modal-body">

        <!-- Section Type de Region -->
        <div class="filter-section">
          <h4><i class="fas fa-map-marker-alt"></i> Types de Région</h4>
          <div class="radio-group checkbox-group twice-group">
            <label class="sub-select" v-for="type in typesRegion" :key="type">
              <input
                type="radio"
                v-model="filters.selectedTypeRegion" 
                :value="type"
              />
              {{ type }}
            </label>
          </div>
        </div>
          
        <!-- Section Devise -->
        <div class="filter-section">
          <h4><i class="fas fa-wallet"></i> Devise</h4>
          <div class="checkbox-group twice-group">
            <label class="sub-select" v-for="currency in currencies" :key="currency">
              <input 
                type="checkbox" 
                v-model="filters.selectedCurrencies" 
                :value="currency" 
                @change="selectCurrency(currency)"> {{ currency }}
            </label>
          </div>
        </div>

        <!-- Filtre achat/vente -->
        <div class="filter-section">
          <h4><i class="fas fa-exchange-alt"></i> Opération de Change</h4>
          <div class="radio-group checkbox-group twice-group">
            <label class="sub-select" v-for="type in typesOperations" :key="type">
              <input
                type="radio"
                v-model="filters.selectedTypeOperation" 
                :value="type"
              />
              {{ type }}
            </label>
          </div>
        </div>

        <!-- Section ouvert/fermé -->
        <div class="filter-section">
          <h4><i class="fas fa-door-open"></i> Statut d'ouverture</h4>
          <div class="checkbox-group">
            <label>
              <input type="checkbox" v-model="filters.isOpen" :value="true" />
              Bureaux ouverts uniquement
            </label>
            
          </div>
        </div>

        <!-- Section Recommandé/Favoris -->
        <div class="filter-section">
          <h4><i class="fas fa-star"></i> Préférences </h4>
          <div class="radio-group checkbox-group twice-group">
            <label>
              <input type="checkbox" v-model="filters.isRecommended" />
              Recommandé
            </label>

            <label v-if="isTokenDefined">
              <input type="checkbox" v-model="filters.isFavorite" />
              Favoris
            </label>
          </div>
        </div>

      </div>

      <div class="modal-buttons">
        <button @click="applyFilters" class="apply-btn">Appliquer les filtres</button>
        <button @click="closeModal" class="cancel-btn">Annuler</button>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'ModalFiltersRates',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    regions: {
      type: Array,
      required: true
    },
  },


  mounted() {
    // Vérifier si des filtres sont sauvegardés dans localStorage
    const savedFilters = localStorage.getItem('savedFilters');
    if (savedFilters) {
      this.filters = JSON.parse(savedFilters);
    }
  },
  computed: {
    isTokenDefined() {
      return localStorage.getItem('tokenUser') !== null;
    },
  },

  data() {
    return {
      filters: {
        selectedCurrencies: ['USD', 'EUR', 'CAN'], // Par défaut, toutes les devises sont sélectionnées
        isOpen: false, // Définit si les bureaux ouverts uniquement ou non
        isRecommended: false,
        isFavorite: false,
        selectedTypeRegion: 'Région', // Sélection par défaut de 'Région'
        selectedTypeOperation: 'Achat & Vente',
      }, 
      typesRegion: ['Département', 'Ville', 'Région'], // Liste des types de région
      typesOperations: ['Achat', 'Vente', 'Achat & Vente'], // liste des opérations
      currencies: ['USD', 'EUR', 'CAN'],
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    applyFilters() {
      this.$emit('apply-filters', this.filters);
      this.closeModal();
    },

    selectCurrency(currency) {
      // Vérifie si la devise est actuellement sélectionnée
      const isCurrentlySelected = this.filters.selectedCurrencies.includes(currency);
      
      // Si la devise est déjà sélectionnée et qu'il n'y a qu'une seule devise, empêcher la désélection
      if (isCurrentlySelected && this.filters.selectedCurrencies.length === 1) {
        return; // Empêche la désélection si c'est la dernière devise sélectionnée
      }

      // Si la devise est désélectionnée
      if (!isCurrentlySelected && this.filters.selectedCurrencies.length === 0) {
        // On peut la désélectionner librement, car il y a plus d'une devise sélectionnée
        this.filters.selectedCurrencies.push(currency); // Ajoute la devise si elle n'était pas déjà là
      }
    },

    filteredRegions() {
      // Logique pour filtrer les régions selon les types sélectionnés (Département, Ville, Région)
      if (!this.filters.selectedTypesRegion.length) {
          return this.regions; // Si aucun type n'est sélectionné, montrer toutes les régions
      }

      return this.regions.filter(region =>
          this.filters.selectedTypesRegion.includes(region.type) // Utiliser la propriété type pour filtrer
      );
    },
  },

  
};
</script>

  
<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    max-width: 90%;
    font-size: 0.8rem;
    max-height: 70vh;
    overflow-y: auto; /* Ajoute le défilement si le contenu dépasse */
    scrollbar-width: none; /* Cache la barre de défilement dans Firefox */
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: #000;
  }
  
  .modal-body {
    display: flex;
    flex-direction: column;
    color: #000;
  }
  
  .filter-section {
    margin-bottom: 5px;
  }
  .filter-section h4{
    color: #FFF;
    background-color: #000;
    border-radius: 15px;
    padding: 10px;
    text-align: left;
  }
  .filter-section h4 i{
    margin-right: 10px;
  }

  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .checkbox-group .select-all {
    display: block;
    text-align: left;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .checkbox-group .sub-select {
    display: inline-block;
    width: calc(33.33% -10px);
    /* margin-right: 10px; */
    margin-right: 10%;
    margin-bottom: 10px;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  
  .modal-buttons button {
    margin-left: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .modal-buttons button:last-child {
    background-color: #dc3545;
  }

  .twice-group {
    display: flex;
    justify-content: space-between; /* Pour espacer les éléments */
    width: 100%; /* Assurez-vous que le div prend toute la largeur */
    /* align-items: middle; */
  }

</style>
  
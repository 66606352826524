<template>
    <Header />
    <PageHeader title="A propos de Kòb Dirèk" />
    <div class="about-page">

        <div class="historic-section">
            <h2><i class="fas fa-feather-alt"></i> Notre Histoire</h2>
            <p>Kòb Dirèk est né de la volonté de faciliter l'accès aux services financiers pour tous les Haïtiens. Fondée en [année], notre entreprise a pour but de répondre à la demande croissante de solutions de paiement électroniques fiables et accessibles. Depuis notre lancement, nous nous engageons à offrir des services innovants qui simplifient la gestion financière de nos utilisateurs.</p>
            <img class="large-image" src="@/assets/images/partners/team.jpg" alt="Resto Lakay">
            <p><span class="little-title">» <i class="fas fa-lightbulb"></i> Innovation :</span> Nous repoussons constamment les limites pour offrir des solutions modernes et efficaces.</p>
            <p><span class="little-title">» <i class="fas fa-universal-access"></i> Accessibilité :</span> Nous croyons que chaque Haïtien mérite un accès facile aux services financiers, indépendamment de sa situation géographique ou économique.</p>
            <p><span class="little-title">» <i class="fas fa-shield-alt"></i> Sécurité :</span> La protection des données et des transactions de nos utilisateurs est notre priorité absolue.</p>
            <p><span class="little-title">» <i class="fas fa-balance-scale"></i> Transparence :</span> Nous opérons avec intégrité et clarté dans toutes nos interactions et nos services.</p>
        </div>

        <div class="team-section">
            <h2><i class="fas fa-user-friends"></i> Notre Équipe</h2>

            <div class="block-item-container">
                <div class="navigation prev" @click="prevItem">
                <i class="fas fa-chevron-left"></i>
                </div>
                <div class="block-item">
                <div v-for="(item, index) in items" :key="index" class="item" :class="{active: index === activeIndex}">
                    <div class="image-container">
                    <img :src="getImage(item.image)" :alt="item.title">
                    <span class="title">{{ item.title }}</span>
                    </div>
                    <div class="text-container">
                    <span class="description">{{ item.description }}</span>
                    </div>
                </div>
                </div>
                <div class="navigation next" @click="nextItem">
                <i class="fas fa-chevron-right"></i>
                </div>
            </div>



        </div>

        <div class="parteners-section">
            <h2><i class="fas fa-handshake"></i> Nos Partenaires</h2>
            
            <div class="partners-bloc-item">
                <div class="image-container">
                    <img src="@/assets/images/partners/logo_benshee.png" alt="Resto Lakay">
                </div>
                <span>BenShee Store</span>
            </div>

            <div class="partners-bloc-item">
                <div class="image-container">
                    <img src="@/assets/images/partners/logo_mcpam.png" alt="Resto Lakay">
                </div>
                <span>MC PAM</span>
            </div>

            <div class="partners-bloc-item">
                <div class="image-container">
                    <img src="@/assets/images/partners/logo_danechoco.png" alt="Resto Lakay">
                </div>
                <span>Dane Choco</span>
            </div>

            <div class="partners-bloc-item">
                <div class="image-container">
                    <img src="@/assets/images/partners/logo_karabela.png" alt="Resto Lakay">
                </div>
                <span>Karabela TV</span>
            </div>

            <div class="partners-bloc-item">
                <div class="image-container">
                    <img src="@/assets/images/partners/logo_friendship.png" alt="Resto Lakay">
                </div>
                <span>Friendship 509</span>
            </div>

            <div class="partners-bloc-item">
                <div class="image-container">
                    <img src="@/assets/images/partners/logo_geekzone.png" alt="Resto Lakay">
                </div>
                <span>GeekZone HT</span>
            </div>

        </div>

        <div class="contact-section">
            <h2><i class="fas fa-address-book"></i> Contact</h2>
            <p>Pour toute question, partenariat ou opportunité, n'hésitez pas à nous contacter.</p>
            <a href="/contact" class="contact-page-link">
                <i class="fas fa-link"></i> Page de Contact
            </a>
            <div class="contact-info">
                <div class="contact-item">
                    <i class="fas fa-envelope icon-bg"></i>
                    <span>Email : </span>
                    <a href="mailto:info@kobdirek.com">info@kobdirek.com</a>
                </div>
                <div class="contact-item">
                    <i class="fas fa-phone-alt icon-bg"></i>
                    <span>Téléphone : </span>
                    <a href="tel:+50940409077">(509) 40409077</a>
                </div>
            </div>
        </div>

        <div class="testimonials">
            <h2><i class="fas fa-comments"></i> Témoignages et Avis</h2>

            <div class="testimonial testimonial-left">
                <p class="testimonial-text testimon">
                    « Utiliser Kòb Dirèk a transformé la manière dont je gère mes finances quotidiennes. C'est rapide, sûr et très pratique! »
                </p>
                <p class="testimonial-signature">
                    - Jonhatan Ulrick (Client Satisfait)
                </p>
            </div>

            <div class="testimonial highlighted testimonial-right">
                <p class="testimonial-text">
                    « Kòb Dirèk est un partenaire clé dans notre mission de rendre les services financiers accessibles à tous. »
                </p>
                <p class="testimonial-signature right">
                    - Partenaire Stratégique
                </p>
            </div>

            <!-- Deux témoignages supplémentaires masqués initialement -->
            <div v-if="showAdditional" class="testimonial testimonial-left">
                <p class="testimonial-text">
                    « Mwen renmen jan Kòb Dirèk fè tranzaksyon m yo vin fasil. M ap di tout moun eseye li pou yon eksperyans rapid e efikas! »
                </p>
                <p class="testimonial-signature">
                    - Klémantine Jacques (Utilisatrice régulière)
                </p>
            </div>

            <div v-if="showAdditional" class="testimonial highlighted testimonial-right">
                <p class="testimonial-text">
                    « Je suis impressionné par la simplicité d'utilisation de Kòb Dirèk. Un excellent outil pour gérer ses finances personnelles. »
                </p>
                <p class="testimonial-signature right">
                    - Nouvel Utilisateur
                </p>
            </div>

            <!-- Bouton "Voir plus" pour afficher les témoignages supplémentaires -->
            <button @click="toggleAdditional" class="view-more-button">
                <span v-if="showAdditional">
                    Voir moins <i class="fas fa-caret-up"></i>
                </span>
                <span v-else>
                    Voir plus <i class="fas fa-caret-down"></i>
                </span>
            </button>
        </div>

        <div class="action-section">
            <h2><i class="fas fa-hand-point-down"></i> Appel à l'Action</h2>
            <p>Explorez notre plateforme en ligne pour découvrir comment simplifier la gestion de vos finances !</p>
            <div class="breadcrumb">
                <a href="/home">Accueil</a>
                <a href="/newsletter">Inscrivez-vous à notre Newsletter</a>
                <a href="https://www.facebook.com/YourPage" target="_blank">Suivez-nous sur Facebook</a>
            </div>

        </div>
    </div>

    <Footer :key="footerKey" />
</template>

<script>
  //import axios from 'axios';
  import Header from './shared/HeaderPage.vue';
  import Footer from './shared/FooterPage.vue';
  import PageHeader from './shared/PageHeader.vue';

  import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

  export default {
    name: 'AboutPage',
    mixins: [refreshFooterMixin],
    components: {
      Header,
      PageHeader,
      Footer
    },

    data() {
        return {            
            items: [
                {
                image: "Benson.jpg",
                title: "Jean Philippe G.B. - CEO",
                description: "Maître en création de logiciels, il mène notre équipe technique avec excellence dès le commencement."
                },
                {
                image: "Dave.jpg",
                title: "Dave Vernet. - CMO",
                description: "Spécialiste en stratégie marketing, il oriente notre vision pour propulser Kòb Dirèk au sommet."
                },
                {
                image: "Stephen.jpg",
                title: "Stephen - HR Manager",
                description: "Expert en RH, il s'assure d'engager les talents d'exception et de maintenir un cadre de travail idéal."
                }
            ],
            activeIndex: 0,  // Initializing activeIndex to 1
            showAdditional: false,
        };
    },
    
    methods: {
        toggleAdditional() {
            this.showAdditional = !this.showAdditional;
        },
        getImage(image) {
        return require(`@/assets/images/partners/${image}`);
        },
        nextItem() {
        this.activeIndex = (this.activeIndex + 1) % this.items.length;
        console.log("Active Index:", this.activeIndex);
        },
        prevItem() {
        this.activeIndex = (this.activeIndex - 1 + this.items.length) % this.items.length;
        console.log("Active Index:", this.activeIndex);
        }
    }


  };

</script>

<style scoped>

.about-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: -20px;
  color: #FFFFFF;
  margin-bottom:80px;
}

.mockup-image {
  width: 150px;
  height: auto;
  margin: 2px 0;
}

.historic-section{
    color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    margin: 20px 0px;
}

.historic-section p{
    text-align: justify;
}
.large-image{
    width: 100%;
    border-radius: 15px;
}
.little-title{
    font-weight: normal;
    font-size: 1.1rem;
    margin-right: 5px;
}

/********************** */

.team-section{
  max-width: 400px;
  margin: 5 auto;
  padding: 5px 10px;
  color: white;
  background-color: #080808;
  border-radius: 5px;
}

.block-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.navigation {
  cursor: pointer;
  font-size: 2rem;
  color: #333;
  padding: 10px;
}

.block-item {
  display: flex;
  overflow: hidden;
  max-width: 600px;
  position: relative;
}

.item {
  min-width: 100%;
  transition: transform 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: none; /* Changed to display: none */
  flex-direction: column;
  align-items: center;
}

.item.active {
  opacity: 1;
  visibility: visible;
  display: flex; /* Changed to display: flex */
}

.item .image-container {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  width: 200px;
  height: 300px; /* Adjusted to keep aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}

.item .image-container img {
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.item .title {
  font-weight: bold;
  margin-top: 10px;
  color: #000103;
  text-align: center;
}

.item .text-container {
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  color: #FFFFFF;
}

.item .text-container .description {
  color: #FFFFFF;
}

/***************************** */

.parteners-section{
    color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    margin: 20px 0px;
}

.partners-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centre les éléments horizontalement */
    gap: 20px; /* Espacement entre les blocs */
}

.partners-bloc-item {
    display: inline-block;
    width: 100px; /* Largeur du bloc */
    text-align: center;
    vertical-align: top; /* Aligne les éléments en haut */
    box-sizing: border-box; /* Inclut les bordures et le padding dans la largeur totale */
    margin: 10px; /* Espacement autour des blocs */
}

.image-container {
    width: 100%; /* Utilise la largeur totale du bloc */
    height: 100px; /* Taille du carré */
    background-color: #ffffff; /* Couleur de l'arrière-plan */
    border-radius: 15px; /* Bord arrondi */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optionnel : ombre pour un effet de profondeur */
    margin-bottom: 10px; /* Espacement entre l'image et le texte */
}

.image-container img {
    max-width: 80%; /* Assurez-vous que l'image est bien centrée et ne dépasse pas du conteneur */
    max-height: 80%;
    border-radius: 10px; /* Légère courbure des coins de l'image */
}

.partners-bloc-item span {
    display: block;
    font-size: 16px;
    /*font-weight: bold;
    /*color: #333; /* Couleur du texte */
}

.contact-section {
    background-color: #1A1A1D; /* Noir avec un léger contraste */
    color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    margin: 20px 0px;
}

.contact-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.contact-section p {
    font-size: 16px;
    margin-bottom: 20px;
}

.contact-page-link {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 16px;
    color: #FFD700; /* Doré pour le lien */
    text-decoration: none;
    transition: color 0.3s;
}

.contact-page-link:hover {
    color: #FFBF00; /* Teinte plus lumineuse pour le survol */
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-item {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.contact-item a{
    color: #178FF5;
    text-decoration: none;
    margin-left: 10px;
}


.testimonials {
  max-width: 400px;
  margin: 5 auto;
  padding: 5px 10px;
  color: white;
  background-color: #080808;
  border-radius: 5px;

}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
}

.testimonial {
  margin-bottom: 20px;
}

.testimonial-text {
  font-size: 1em; /* Réduire la taille du texte */
  line-height: 1.4;
}

.testimonial-signature {
  text-align: left;
  margin-top: 10px;
  font-style: italic;
}

.highlighted {
  /*background-color: #000103; /* Fond noir nuancé */
  color: white;
  padding: 15px;
  border-radius: 10px;
}

.right {
  text-align: right;
}

.testimonial-left{
    text-align: left;
    border: 1px #FFF solid;
    font-size: 0.9rem;
    border-radius: 10px;
    padding: 2px 5px;
}

.testimonial-right{
    text-align: right;
    background-color: #FFFFFF;
    padding: 2px 5px;
    margin: 0 5;
    color: #000;
    font-size: 0.9rem;
    border-radius: 10px;
}

.view-more-button {
    background-color: #016a40;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 0.9em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 15px;
}

.view-more-button:hover {
  background-color: #005934;
}

.action-section{
    color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    margin: 20px 0px;
}

.breadcrumb {
    font-size: 16px;
    color: #333;
}

.breadcrumb a {
    text-decoration: none;
    color: #007bff;
}

.breadcrumb a:hover {
    text-decoration: underline;
}

.breadcrumb a:not(:last-child)::after {
    content: ' | ';
    color: #333;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

</style>
<template>
    <Header />

    <div class="activation-page">
      <h1>Activation de compte</h1>
      <img src="@/assets/images/ewallet_03.png" alt="Mockup Image" class="mockup-image">
      <div v-if="message" :class="{'error-message': isError, 'success-message': !isError}">
        {{ message }}
      </div>
      <div v-else>
        <div class="loading-container" v-if="isLoading">
          <i class="fas fa-spinner fa-spin loading-icon"></i>
        </div>
        <button @click="activateAccount" class="activate-button" v-else>
          Activate Account
        </button>
      </div>
      <button v-if="!isLoading && message" @click="goToHome" class="btn-home">
        {{ $t('pageRecoveryPassword.accueil') }} <i class="fas fa-home"></i>
      </button>
    </div>
</template>
  
  <script>
  import axios from 'axios';
  import Header from '../shared/HeaderPage.vue';
  import { SERVER_IP } from '../../config';
  
  export default {
    name: 'AccountActivation',
    components: {
      Header
    },

    data() {
      return {
        message: '',
        isError: false,
        isLoading: false,
      };
    },
    created() {
      this.activateAccount();
    },
    methods: {
      async activateAccount() {
        this.isLoading = true;
        const token = this.$route.query.token;
        const url = `${SERVER_IP}/activate`;
        try {
          // Ajout d'un délai de 10 secondes avant de faire la requête API
          await new Promise(resolve => setTimeout(resolve, 2000));
          const response = await axios.post(url, { token });
          this.message = response.data.message;
          this.isError = false;
        } catch (error) {
          this.message = error.response.data.error || 'Une erreur s\'est produite lors de l\'activation du compte.';
          this.isError = true;
        } finally {
          this.isLoading = false;
        }
      },
      goToHome() {
        this.$router.push('/');
      },
    },
  };
  </script>
  
  <style scoped>
  .activation-page {
    text-align: center;
    margin-top: 50px;
  }
  
  .activation-page h1 {
    color: #FFFFFF;
  }
  
  .mockup-image {
    width: 300px;
    height: auto;
    margin: 20px 0;
  }
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: #FFFC00;
  }
  
  .activate-button {
    background-color: green;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 0 auto;
    display: inline-block;

  }
  
  .activate-button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  
  .loading-icon {
    font-size: 36px;
    color: #FFFFFF;
  }
  
  .btn-home {
    display: inline-flex;
    align-items: center;
    background-color: #016A40; /* Couleur de fond du bouton */
    color: white; /* Couleur du texte du bouton */
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .btn-home i {
    margin-right: 10px;
  }

  .btn-home:hover {
    background-color: #005934; /* Couleur du bouton au survol */
  }
  </style>
  
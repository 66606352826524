<template>
    <Header />
    <PageHeader :title="$t('pageConfirmDeposit.title')" />

    <div class="invoice-block"  v-if="activeIndex === 0">
        <div class="loading-screen">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
    </div>

    <div class="block-a2f" v-if="activeIndex === 3">
        <img src="@/assets/images/confirmed-register.png" alt="confirmation enregistrement" class="mockup-image" />
        <p>Vous avez confirmé le dépôt avec succès.</p>
        <button @click="goToHome" class="btn-submit">
            {{ $t('pageRecoveryPassword.accueil') }} <i class="fas fa-home"></i>
        </button>
    </div>

    <div class="invoice-block"  v-if="activeIndex === 1">
    
        <img src="@/assets/images/ewallet_46.png" alt="Mockup d'interface de connexion" class="mockup-image">
        
        <!-- Détails du contact -->
        <div class="invoice-detail">
        <label>Déposant :</label>
        <span>{{ invoice.fullname || 'Nom indisponible' }} ({{ invoice.phone || 'No indisponible' }})</span>
        </div>

        <!-- Détails du montant -->
        <div class="invoice-detail">
        <label>Montant :</label>
        <span>
            {{ invoice.currency === 'HTG' ? formatCurrency(invoice.amount, 'HTG', 'fr-HT') : formatCurrency(invoice.amount, 'USD', 'en-US') }}
        </span>
        </div>

        <!-- Détails de la devise -->
        <div class="invoice-detail">
        <label>Devise :</label>
        <span>{{ invoice.currency }}</span>
        </div>

        <!-- Détails des frais -->
        <div class="invoice-detail">
        <label>Frais de Transaction :</label>
        <span>{{ invoice.currency === 'HTG' ? formatCurrency(invoice.fee, 'HTG', 'fr-HT') : formatCurrency(invoice.fee, 'USD', 'en-US') }}</span>
        </div>

        <!-- Détails du montant total -->
        <div class="invoice-detail total">
            <label>Montant Total :</label>
            <span>{{ invoice.currency === 'HTG' ? formatCurrency(invoice.amount + invoice.fee, 'HTG', 'fr-HT') : formatCurrency(invoice.amount + invoice.fee, 'USD', 'en-US') }}</span>
        </div>
        <!-- Détails du montant total -->
        <div class="invoice-detail total">
            <label>Commision :</label>
            <span>{{ invoice.currency === 'HTG' ? formatCurrency(invoice.commission, 'HTG', 'fr-HT') : formatCurrency(invoice.commission, 'USD', 'en-US') }}</span>
        </div>
        
        <h4 class="title-pin">Insérer votre pin</h4>

        <div class="pin-container">
            <div class="pin-inputs">
                <!-- Les inputs avec ref dynamique -->
                <input v-for="(digit, index) in pin" 
                    :key="index" 
                    v-model="pin[index]" 
                    maxlength="1"
                    :ref="'pin' + index" 
                    @input="onInput(index)" 
                    @keydown.backspace="onBackspace(index)" 
                    :type="isRevealed ? 'text' : 'password'" 
                    class="pin-box" 
                    inputmode="numeric" 
                    pattern="[0-9]*"
                    @keypress="isNumber($event)" />
                
                <!-- Icône pour révéler le code PIN -->
                <i class="fas fa-eye eye-icon" @mousedown="revealPin" @mouseup="hidePin" @mouseleave="hidePin"></i>
            </div>
        </div>


        <!-- Bouton de validation avec une icône FontAwesome -->
        <button type="submit" class="btn-submit" :disabled="loadingPin" @click="concluate">
        <span v-if="loadingPin">
            Transfert en cours <i class="fas fa-spinner fa-spin"></i>
        </span>
        <span v-else>
            Valider <i class="fas fa-check-circle"></i>
        </span>
        </button>


        <div>
        <span class="error-message validate-error" v-if="pinError">{{ pinError }}</span>
        </div>

    </div>

    <Footer :key="footerKey" />

</template>

<script>

import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import { formatCurrency, checkAuth } from '@/utils/validationUtils';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

import axios from 'axios';
import { SERVER_IP } from '../../config';

export default {
    name: 'ConfirmDeposit',
    mixins: [refreshFooterMixin],
    components: {
      Header,
      PageHeader,
      Footer,
    },
    created(){
        checkAuth();
    },

    mounted() {
        // Récupérer la variable GET 'ref' depuis l'URL
        const ref = this.$route.query.ref;

        if (!ref) {
            this.$router.go(-1);
        } else {
            //this.fetchInvoice(ref);
            this.fetchInvoice(ref);
        }
    },

    data() {
        return {
            invoice: {
                fullname: '',
                phone: '',
                currency: '',
                amount: 0,
                fee: 0,
                commission:0,
                type: '',
                date: '',
                status: '',
                code: 0,
            },
            pin: ["", "", "", ""],
            loadingPin: false,
            pinError: '',
            isRevealed: false,
            activeIndex: 0,
        }
    },

    methods: {
        formatCurrency(amount, currency, locale) {
            return formatCurrency(amount, currency, locale);
        },
        goToHome() {
            this.$router.push('/home');
        },
        goTo2AF(){
            this.$router.push('/create-pin');
        },
        scrollToError(field) {
            const element = document.getElementById(field);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            this.loadingPin = false;
        },

        // Empêche l'utilisateur d'entrer des lettres
        isNumber(event) {
            const charCode = event.keyCode ? event.keyCode : event.which;
            if (charCode < 48 || charCode > 57) { // 48-57 correspond aux chiffres 0-9
                event.preventDefault();
            }
        },

        // Lorsque l'utilisateur entre un chiffre dans un input
        onInput(index) {
            if (this.pin[index] && index < this.pin.length - 1) {
                // Si le champ est rempli, passer automatiquement au champ suivant
                this.$nextTick(() => {
                    const nextInput = this.$refs['pin' + (index + 1)][0];
                    if (nextInput) nextInput.focus();
                });
            }
            this.pinError = '';
        },

        // Gestion de la suppression d'un chiffre
        onBackspace(index) {
            if (!this.pin[index] && index > 0) {
                // Si le champ est vide après suppression, revenir au champ précédent
                this.$nextTick(() => {
                    const prevInput = this.$refs['pin' + (index - 1)][0];
                    if (prevInput) prevInput.focus();
                });
            } 
            this.pinError = '';
        },

        // Révéler le PIN pendant 1 seconde
        revealPin() {
            this.isRevealed = true;
            setTimeout(() => {
                this.isRevealed = false;
            }, 3000); // Masque le code après 3 secondes
        },

        hidePin() {
            this.isRevealed = false;
        },

        // Validation du PIN
        validatePin() {
            if (this.pin.join('').length === 4) {
                return true;
            } else {
                return false;
            }
        },

        async fetchInvoice(ref) {

            const url = `${SERVER_IP}/basic-deposit/invoice-confirm`;
            try {
                const userId = checkAuth();
                const response = await axios.post(url, { 
                    ref: ref,
                    userId: userId
                });
                if (response.status === 200) {
                    this.invoice = response.data;
                    this.activeIndex = 1;
                }
                
            } catch (error) {
                if (error.response){
                    if (error.response.data.error === 'not_found' || error.response.data.error === 'fraud') {
                        this.$router.go(-1);
                    }
                    else {
                        this.pinError = "Erreur inconnue. Veuillez réessayer.";
                        this.scrollToError('pinError');
                    }
                    
                }
            } finally {
                this.loadingPin = false; // Assurez-vous de désactiver le chargement dans tous les cas
            }
        },

        async concluate(){
            this.loadingPin = true;
            if(!this.validatePin()){
                this.pinError = "Veuillez remplir tous les champs avec des chiffres.";
                this.loadingPin = false;
                return;
            }

            const url = `${SERVER_IP}/basic-deposit/confirm`;
            try {
                const userId = checkAuth();
                const response = await axios.post(url, { 
                    ref: this.$route.query.ref,
                    pin: this.pin.join(''),
                    userId: userId
                });
                if (response.status === 200) {
                    this.activeIndex = 3;
                }
                
            } catch (error) {
                if (error.response && error.response.data.error === 'invalid_pin'){
                    this.pinError = "Votre code PIN est incorrecte";
                    this.scrollToError('pinError');
                }
                else{
                    this.pinError = "Erreur inconnue. Veuillez réessayer.";
                    this.scrollToError('pinError');
                    this.loadingPin = false;
                }
                
            }
            finally {
                this.loadingPin = false; // Assurez-vous de désactiver le chargement dans tous les cas
            }
        },
    }

};

</script>

<style scoped>

.loading-screen {
    margin-top: 50%;
    font-size: 2.5rem;
}

.block-a2f{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
}
.block-a2f p{
  font-size: 1rem;
}
.block-a2f p a{
    color: #03a5fc;
    text-decoration: none;
}
.block-a2f p a:hover{
    color: #08489c;
}

.mockup-image {
max-width: 120px;
margin: 20px auto;
display: block;
}

.invoice-block{
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5%;
}


.invoice-detail {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.invoice-detail.total {
font-weight: bold;
border-top: 1px solid #ddd;
padding-top: 10px;
}

.validate-button {
background-color: #28a745;
color: white;
padding: 10px 20px;
border: none;
border-radius: 5px;
cursor: pointer;
display: flex;
align-items: center;
}

.validate-button i {
margin-right: 8px;
}

.title-pin{
font-weight: normal;
font-size: 1.5rem;
}

.pin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pin-label {
  margin-bottom: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.pin-inputs {
  display: flex;
  gap: 10px;
  position: relative;
}

.pin-box {
  width: 50px;
  height: 50px;
  border: 2px solid #000103;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.eye-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.btn-submit {
background-color: #016a40;
color: white;
border: none;
padding: 10px 20px;
font-size: 1em;
cursor: pointer;
border-radius: 5px;
transition: background-color 0.3s;
margin-top: 15px;
}

.btn-submit:hover {
background-color: #005934;
}

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
}
</style>
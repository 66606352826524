// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './utils/translator'; 

import '@/assets/css/style.css';
import './assets/js/recaptcha.js';
import 'daterangepicker/daterangepicker.css';



const app = createApp(App);

// Création d'une instance i18n exportée pour être utilisée partout dans Vue

app.use(router);
app.use(i18n);
app.mount('#app');

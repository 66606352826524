<template>
    <div class="modal-overlay" @click.self="closeModalIndevelopment">
      <div class="modal-content">
        <h2>Fonctionnalité à venir</h2>
        <p>Nous développons actuellement cette fonctionnalité. Elle sera accessible dans une prochaine version. Merci pour votre compréhension.</p>
        <button class="close-btn" @click="closeModalIndevelopment"><i class="fas fa-close"></i> Fermer</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalInDevelopment',
    methods: {
      closeModalIndevelopment() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Styles pour l'arrière-plan */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* arrière-plan semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Toujours devant les autres éléments */
  }
  
  /* Styles pour le modal */
  .modal-content {
    background-color: #ffffff; /* Arrière-plan blanc */
    color: #000000; /* Texte en noir */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 300px;
    width: 90%;
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  /* Styles pour le bouton de fermeture */
  .close-btn {
    background-color: #f44336; /* Rouge pour indiquer la fermeture */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .close-btn:hover {
    background-color: #d32f2f;
  }
  </style>
  